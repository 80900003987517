import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useFlexLayoutContext } from '../../../../components/layouts/flex-layout-context'
import PortfolioGrid from '../../components/portfolio-grid'
import usePortfoliosQuery from '../../data/use-portfolios-query'

function PortfoliosGridFlexView() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { portfolioRef } = useParams()
  const { openView, updateTabName, getActiveTabId } = useFlexLayoutContext()
  const tabId = useMemo(() => getActiveTabId(), [])

  const portfoliosResponse = usePortfoliosQuery()
  const portfolios = portfoliosResponse.data?.data

  useEffect(() => {
    if (!portfolioRef && portfoliosResponse.isSuccess) {
      const portfolio = portfolios?.[0]
      if (portfolio) {
        navigate(`/portfolios/${portfolio.portfolio_ref}`)
      }
    }
  }, [portfolioRef, portfoliosResponse])

  function handlePortfolioSelect(portfolioRef: string) {
    navigate(`/portfolios/${portfolioRef}`)
  }

  function onSecuritySelect(assetRef: string) {
    openView(`/portfolios/${portfolioRef}/${assetRef}`, t('portfolio:security_details.title'), true)
  }

  function handleLiquiditySelect(assetRef: string) {
    openView(`/liquidity?assetRef=${assetRef}`, t('portfolio:liquidity'))
  }

  function handleNewTrade() {
    openView(`/trade-ticket/${portfolioRef}`, t('tradeTicket:trade_ticket'))
  }

  function handleNewCashflow() {
    openView(`/trade-ticket/${portfolioRef}?cashflow`, t('tradeTicket:cashflow'))
  }

  function handleClosePosition(yRef: string, aggregations: string[], datasetRef: string | null) {
    let url = `/trade-ticket/${portfolioRef}/y-ref/${encodeURIComponent(yRef)}/close-out-position`
    const params = []

    if (aggregations.length) {
      params.push(`aggregations=${aggregations.join(',')}`)
    }

    if (datasetRef) {
      params.push(`datasetRef=${datasetRef}`)
    }

    if (params.length) {
      url += `?${params.join('&')}`
    }

    openView(url, t('tradeTicket:trade_ticket'))
  }

  function handleOpenCompliance() {
    openView(`/compliance/${portfolioRef}`, t('common:navigation.compliance'))
  }

  function handleTabNameUpdate(name: string | undefined) {
    if (tabId && name) {
      updateTabName(tabId, name)
    }
  }

  return (
    <PortfolioGrid
      portfolioRef={portfolioRef}
      onSecuritySelect={onSecuritySelect}
      onPortfolioSelect={handlePortfolioSelect}
      onLiquiditySelect={handleLiquiditySelect}
      onNewTrade={handleNewTrade}
      onNewCashflow={handleNewCashflow}
      onClosePosition={handleClosePosition}
      onOpenCompliance={handleOpenCompliance}
      onTabNameUpdate={handleTabNameUpdate}
    />
  )
}

export default PortfoliosGridFlexView
