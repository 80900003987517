import { AxiosError, AxiosResponse } from 'axios'
import { format } from 'date-fns'
import { ShareViewBody } from '../../modules/portfolios/data/use-share-view-mutation'
import axios from './axios'
import { ApiKeyResponse, CreateApiKeyPayload, RevealApiKeyResponse } from './types/api-key'
import { AssetImport, RequestAssetImportPayload } from './types/asset-import'
import {
  GenerateDatatocPayload,
  GenerateDatatocResponse,
  StaticOptionsResponse,
  StaticResponse,
  StaticUploadInspection,
  StaticUploadOptionsResponse,
  StaticUploadParams,
  SymbologyDisplayHeader,
} from './types/asset-static-data'
import { AuditFilters, AuditLog, AuditNavigator } from './types/audit-trail'
import { LoginConfirmationResponse, LoginRequestResponse, UserSelf } from './types/auth'
import {
  BankAccountsNavigator,
  CounterpartyUnsettledDeal as BankAccountsUnsettledDeal,
  CorrectCounterpartySettlementPayload,
  CorrectCustodianTransactionPayload,
  CounterpartyAccountHeader,
  CounterpartySettlement,
  CustodianTransactionResponse,
  InsertCounterpartySettlementPayload,
  InsertCustodianTransactionPayload,
} from './types/bank-accounts'
import { Broker } from './types/broker'
import {
  Classification,
  ClassificationPayload,
  ClassificationValue,
  ClassificationValuePayload,
} from './types/classifications'
import { CloseOutPositionResponse } from './types/close-out-position'
import { ComplianceResult, PortfolioComplianceResponse } from './types/compliance'
import { Datapoint, DatapointDetails, DatapointPayload, EditDatapointValue } from './types/datapoint'
import { DatapointHistory } from './types/datapoint-history'
import DatapointOptionsResponse from './types/datapoint-options'
import { Dataset, DatasetBody, UserDetailsDataset } from './types/dataset'
import DatasetWithDatapoints from './types/dataset-with-datapoints'
import { DealResponse } from './types/deal'
import { GridRequest, GridResponse, UpdatedGridCell } from './types/grid-data'
import GridDataOptions from './types/grid-data-options'
import { GridDataViewCreateBody, GridDataViewFilters, GridDataViewUpdateBody } from './types/grid-data-view'
import { AddGroupPayload, Group, GroupWithMembers, GroupsWithMembers } from './types/group'
import LiquidityMonitor, { LiquidityMonitorPayload, LiquidityNavigator } from './types/liquidity'
import { AddModelPayload, Model, ModelDetails } from './types/models'
import {
  CreateManualFillBody,
  CreateManualFillParams,
  CreatePlacementBody,
  CreatePlacementParams,
  Order,
} from './types/order'
import { CreateOrderBatchBody, OrderBatchGridResponse, OrderBatchHeader } from './types/order-batch'
import Permission from './types/permission'
import {
  ExportPortfolioData,
  ExportPortfolioParams,
  Portfolio,
  PortfolioHeader,
  PortfolioOptions,
  PortfolioPayload,
  PortfolioUploadOptions,
  PortfolioUploadParams,
  RegularPortfolio,
  SandboxPortfolio,
} from './types/portfolio'
import { PriceUpPayload, TradeParticulars } from './types/pricing'
import {
  BankRecordsUploadParams,
  ReconGridResponse,
  ReconcileTxnsPayload,
  RecordsUploadCommitResponse,
  RecordsUploadInspectResponse,
  RecordsUploadOptionsResponse,
  RecordsUploadVerifyResponse,
} from './types/reconciliation'
import { Security, SecurityMoniker } from './types/security'
import SecurityMasterNavigator from './types/security-master-navigator'
import Tenant, { AddTenantPayload } from './types/tenant'
import {
  AssetMeta,
  CloseOutSegmentPayload,
  ConfirmDealsPayload,
  ConsignNewTicketPayload,
  CorrectTransactionPayload,
  TradeTicketResponse,
} from './types/trade-ticket'
import { TransactionResponse } from './types/transaction'
import { CreateUserBody, EditUserBody, UserInTenant } from './types/user'
import { ViewList, ViewShareOptions, ViewType } from './types/view'
import { CreateWhatIfPayload, UpdateWhatIfPayload, WhatIf } from './types/what-if'

const api = {
  loginRequest: (username: string, password: string) => {
    return axios.post<LoginRequestResponse>(`/user/login/${username}/request`, { password })
  },
  loginConfirmation: (username: string, password: string, mfa_code: string) => {
    return axios.post<LoginConfirmationResponse>(`/user/login/${username}/confirmation`, { password, mfa_code })
  },
  logout: () => {
    return axios.post(`/user/logout`)
  },
  forgotPassword: (username: string) => {
    return axios.post(`/user/passwordresetrequest/${username}`)
  },
  resetPassword: (token: string, newPassword: string) => {
    return axios.post(`/user/passwordreset/${token}`, { new_password: newPassword })
  },
  changePassword: (currentPassword: string, newPassword: string) => {
    const payload = { old_password: currentPassword, new_password: newPassword }
    return axios.post(`/user/self/changepassword`, payload)
  },
  getUserSelf: () => {
    return axios.get<UserSelf>('/user/self')
  },
  editAccount: (user: EditUserBody) => {
    return axios.patch('/user/self', user)
  },
  getTenants: () => {
    return axios.get<Tenant[]>('/tenants')
  },
  setCurrentTenat: (tenantRef: string) => {
    return axios.post(`/tenant/${tenantRef}/setcurrent`)
  },
  getApiKeys: (userRef: string) => {
    return axios.get<ApiKeyResponse[]>(`/users/${userRef}/api-keys`)
  },
  revealApiKey: (userRef: string, apiKeyId: number) => {
    return axios.get<RevealApiKeyResponse>(`/users/${userRef}/api-keys/${apiKeyId}/reveal`)
  },
  createApiKey: (userRef: string, payload: CreateApiKeyPayload) => {
    return axios.post(`/users/${userRef}/api-keys`, payload)
  },
  deleteApiKey: (userRef: string, apiKeyId: number) => {
    return axios.delete(`/users/${userRef}/api-keys/${apiKeyId}`)
  },
  getPortfolios: () => {
    return axios.get<PortfolioHeader[]>('/portfolio/list')
  },
  getPortfolio: (
    portfolioRef: string,
    datasetRef: string | null,
    body: GridRequest,
    loadAllColumns: boolean,
    asOfDate: string | null,
    signal: AbortSignal | undefined
  ) => {
    const params = []

    if (datasetRef) {
      params.push(`dataset=${datasetRef}`)
    }

    if (loadAllColumns) {
      params.push('all_columns=true')
    }

    if (asOfDate) {
      params.push(`as_of_date=${asOfDate}`)
    }

    return axios.post<GridResponse>(`/portfolio/${portfolioRef}/grid?${params.join('&')}`, body, { signal })
  },
  getPortfolioGridOptions: async (portfolioRef: string) => {
    return axios.get<GridDataOptions>(`/portfolio/${portfolioRef}/options`)
  },
  getPortfolioGridCompliance: async (portfolioRef: string, asOfDate?: string | null) => {
    let url = `/portfolio/${portfolioRef}/grid/compliance`

    if (asOfDate) {
      url += `?as_of_date=${asOfDate}`
    }

    return axios.get<PortfolioComplianceResponse>(url)
  },
  getPortfolioCompliance: async (portfolioRef: string, asOfDate?: string | null) => {
    let url = `/portfolio/${portfolioRef}/compliance`

    if (asOfDate) {
      url += `?as_of_date=${asOfDate}`
    }

    return axios.get<ComplianceResult>(url)
  },
  createWhatIf: (
    portfolioRef: string,
    payload: CreateWhatIfPayload,
    datasetRef?: string | null,
    asOfDate?: string | null
  ) => {
    const params = []

    if (datasetRef) {
      params.push(`dataset=${datasetRef}`)
    }

    if (asOfDate) {
      params.push(`as_of_date=${asOfDate}`)
    }

    return axios.post<WhatIf>(`/portfolio/${portfolioRef}/whatif?${params.join('&')}`, payload)
  },
  updateWhatIf: (portfolioRef: string, whatifRef: string, payload: UpdateWhatIfPayload, asOfDate?: string | null) => {
    const params = []

    if (asOfDate) {
      params.push(`as_of_date=${asOfDate}`)
    }

    return axios.post<UpdatedGridCell[]>(
      `/portfolio/${portfolioRef}/whatif/${whatifRef}/update?${params.join('&')}`,
      payload
    )
  },
  getTransaction: (lei: string, transactionRef: string) => {
    return axios.get<TransactionResponse>(`/lei/${lei}/transactions/${transactionRef}`)
  },
  getTransactions: (portfolioRef: string, datasetRef: string | null, body: GridRequest, loadAllColumns: boolean) => {
    const params = []

    if (datasetRef) {
      params.push(`dataset=${datasetRef}`)
    }

    if (loadAllColumns) {
      params.push('all_columns=true')
    }

    return axios.post<GridResponse>(`/portfolio/${portfolioRef}/grid?${params.join('&')}`, {
      ...body,
      row_type: 'portfolio_transactions',
    })
  },
  getTransactionGridOptions: async (portfolioRef: string) => {
    return axios.get<GridDataOptions>(`/portfolio/${portfolioRef}/options?txns`)
  },
  getDeal: async (lei: string, dealRef: string) => {
    return axios.get<DealResponse>(`/lei/${lei}/deals/${dealRef}`)
  },
  getCloseOutPosition: async (
    portfolioRef: string,
    yRef: string,
    aggregations: string | null,
    datasetRef: string | null
  ) => {
    let url = `/portfolio/${portfolioRef}/yref/${encodeURIComponent(yRef)}/close-out-position`
    const params = []

    if (aggregations) {
      params.push(`aggregations=${aggregations}`)
    }

    if (datasetRef) {
      params.push(`dataset=${datasetRef}`)
    }

    if (params.length) {
      url += `?${params.join('&')}`
    }

    return axios.get<CloseOutPositionResponse>(url)
  },
  getViews: async (
    viewType: ViewType,
    { includeSharedWith, includeViewOptions }: { includeSharedWith?: boolean; includeViewOptions?: boolean }
  ) => {
    const params = []

    if (viewType) {
      params.push(`view_type=${viewType}`)
    }

    if (includeSharedWith) {
      params.push('include_shared_with')
    }

    if (includeViewOptions) {
      params.push('include_view_options')
    }

    return axios.get<ViewList>(`/views?${params.join('&')}`)
  },
  createGridDataView: async (body: GridDataViewCreateBody) => {
    if (!!body.view_options) {
      body.view_options.columns = updateGridViewRequestColumns(body.view_options.columns)
    }
    return axios.post(`/views`, body)
  },
  updateGridDataView: async (viewRef: string, body: GridDataViewUpdateBody) => {
    if (!!body.view_options) {
      body.view_options.columns = updateGridViewRequestColumns(body.view_options.columns)
    }

    return axios.patch(`/views/${viewRef}`, body)
  },
  setViewAsDefault: async (viewRef: string, assetType: string | null) => {
    return axios.post(`/views/${viewRef}/set-default`, { asset_type: assetType })
  },
  getViewShareOptions: async (viewRef: string) => {
    return axios.get<ViewShareOptions>(`/views/${viewRef}/share-options`)
  },
  shareView: async (viewRef: string, body: ShareViewBody) => {
    return axios.post<ViewShareOptions>(`/views/${viewRef}/share`, body)
  },
  unshareView: async (viewRef: string, groupRef: string) => {
    return axios.delete<ViewShareOptions>(`/views/${viewRef}/groups/${groupRef}/unshare`)
  },
  deleteGridDataView: async (viewRef: string) => {
    return axios.delete(`/views/${viewRef}`)
  },
  getTradeTicket: async () => {
    return axios.get<TradeTicketResponse>('/tradeticket')
  },
  getAssetsSummary: () => {
    return axios.get<SymbologyDisplayHeader[]>('/symbology/all_display_headers')
  },
  getSecurityMoniker: (identifierType: string, identifier: string) => {
    return axios.get<SecurityMoniker>(`/symbology/lookup/${identifierType}/${identifier}`)
  },
  getAssetMeta: (assetRef: string) => {
    return axios.get<AssetMeta>(`/asset/${assetRef}/meta`)
  },
  getBrokers: (lei: string) => {
    return axios.get<Broker[]>(`/lei/${lei}/brokers`)
  },
  getOrderBatches: () => {
    return axios.get<OrderBatchHeader[]>('/orderbatches')
  },
  getOrderBatchGrid: async (
    orderBatchRef: string,
    aggregations: string[] | null = null,
    filters: GridDataViewFilters | null = null
  ) => {
    const body = {
      aggregation: aggregations,
      filters,
    }
    const response = await axios.post<OrderBatchGridResponse>(`orderbatch/${orderBatchRef}/grid`, body)

    response.data.panels = []
    response.data.headings.forEach((heading) => {
      heading.position = 'maingrid'
    })

    return response
  },
  createOrderBatch: (body: CreateOrderBatchBody) => {
    return axios.post(`/orderbatch`, body)
  },
  getOrder: (orderRef: string) => {
    return axios.get<Order>(`/order/${orderRef}`)
  },
  consignNewTicket: (payload: ConsignNewTicketPayload) => {
    return axios.post(`/tickets/new`, payload)
  },
  correctTransaction: (transactionRef: string, payload: CorrectTransactionPayload) => {
    return axios.post(`/transactions/${transactionRef}/correct`, payload)
  },
  cancelTransaction: async (lei: string, txnRef: string) => {
    return axios.delete(`/lei/${lei}/transactions/${txnRef}`)
  },
  duplicateTransaction: async (txnRef: string) => {
    return axios.post(`/transactions/${txnRef}/duplicate`)
  },
  confirmDeals: (payload: ConfirmDealsPayload) => {
    return axios.post(`/deals/confirm`, payload)
  },
  getCloseOutTransaction: async (lei: string, segmentRef: string) => {
    return axios.get<CloseOutPositionResponse>(`/lei/${lei}/transactions/segments/${segmentRef}/close-out`)
  },
  closeOutTransactionCommit: (lei: string, segmentRef: string, body: CloseOutSegmentPayload) => {
    return axios.post<CloseOutPositionResponse>(
      `/lei/${lei}/transactions/segments/${segmentRef}/close-out/commit`,
      body
    )
  },
  closeOutTransactionVerify: (lei: string, segmentRef: string, body: CloseOutSegmentPayload) => {
    return axios.post<CloseOutPositionResponse>(
      `/lei/${lei}/transactions/segments/${segmentRef}/close-out/verify`,
      body
    )
  },
  createPlacement: (params: CreatePlacementParams) => {
    const body: CreatePlacementBody = {
      broker_account_id: params.brokerAccountId,
      amount: params.amount,
    }
    return axios.post(`/order/${params.orderRef}/placement`, body)
  },
  createManualFill: (params: CreateManualFillParams) => {
    const body: CreateManualFillBody = {
      broker_account_id: params.brokerAccountId,
      amount: params.amount,
      price: params.price,
    }
    return axios.post(`/order/${params.orderRef}/fill/manual`, body)
  },
  getUsers: () => {
    return axios.get<UserInTenant[]>('/tenant/current/users')
  },
  getUser: (userRef: string) => {
    return axios.get<UserInTenant>(`tenant/current/user/${userRef}`)
  },
  createUser: (body: CreateUserBody) => {
    return axios.post('/user', body)
  },
  editUser: (userRef: string, body: EditUserBody) => {
    return axios.patch(`/user/${userRef}`, body)
  },
  deleteUser: (userRef: string) => {
    return axios.delete(`tenant/current/users/${userRef}`)
  },
  getDatasets: () => {
    return axios.get<Dataset[]>('datasets/owned')
  },
  getDataset: (datasetRef: string) => {
    return axios.get<Dataset>(`dataset/owned/${datasetRef}`)
  },
  getGroups: () => {
    return axios.get<Group[]>('groups')
  },
  getTenantGroups: () => {
    return axios.get<Group[]>('/tenant/current/groups')
  },
  createDataset: (groupRef: string, body: DatasetBody) => {
    return axios.post(`group/${groupRef}/dataset`, body)
  },
  editDataset: (datasetRef: string, body: DatasetBody) => {
    return axios.patch(`dataset/${datasetRef}`, body)
  },
  deleteDataset: (datasetRef: string) => {
    return axios.delete(`dataset/${datasetRef}`)
  },
  getSecurityDetails: async (assetRef: string) => {
    const response = await axios.get<Security>(`/asset/${assetRef}`)

    response.data.fields.sort((a, b) => a.datapoint_name.localeCompare(b.datapoint_name))

    return response
  },
  getCurrentPermissions: (groupRef: string) => {
    return axios.get<Permission[]>(`/group/${groupRef}/permissions/current`)
  },
  getAvailablePermissions: (groupRef: string) => {
    return axios.get<Permission[]>(`/group/${groupRef}/permissions/available`)
  },
  addPermission: (groupRef: string, permissionTag: string) => {
    return axios.post(`/group/${groupRef}/permissions/${permissionTag}`)
  },
  removePermission: (groupRef: string, permissionTag: string) => {
    return axios.delete(`/group/${groupRef}/permissions/${permissionTag}`)
  },
  changeUserTenantAdminStatus: (userRef: string, body: { is_tenant_admin: boolean }) => {
    return axios.patch(`/tenant/current/users/${userRef}`, body)
  },
  getCurrentPortfolios: (groupRef: string) => {
    return axios.get<Portfolio[]>(`/portfolio/group/${groupRef}/portfolios/current`)
  },
  getAvailablePortfolios: (groupRef: string) => {
    return axios.get<Portfolio[]>(`/portfolio/group/${groupRef}/portfolios/available`)
  },
  addPortfolio: (groupRef: string, portfolioRef: string) => {
    return axios.post(`/portfolio/group/${groupRef}/portfolios/${portfolioRef}`)
  },
  removePortfolio: (groupRef: string, portfolioRef: string) => {
    return axios.delete(`/portfolio/group/${groupRef}/portfolios/${portfolioRef}`)
  },
  getDatapoints: async (datasetRef: string) => {
    const response = await axios.get<Datapoint[]>(`dataset/${datasetRef}/datapoints/display`)

    response.data.sort((a, b) => a.datapoint_name.localeCompare(b.datapoint_name))

    return response
  },
  getDatapoint: (datapointRef: string) => {
    return axios.get<DatapointDetails>(`/datapoint/${datapointRef}`)
  },
  removeDatapoint: (datasetRef: string, datapointRef: string) => {
    return axios.delete(`dataset/${datasetRef}/datapoint/${datapointRef}`)
  },
  deleteDatapoint: (datapointRef: string) => {
    return axios.delete(`datapoint/${datapointRef}`)
  },
  updateDatapointActiveAlias: (datasetRef: string, datapointRef: string, activeAliasId: number | null) => {
    return axios.patch(`/dataset/${datasetRef}/datapoint/${datapointRef}`, {
      active_alias_id: activeAliasId,
    })
  },
  getDatasetsWithDatapoints: () => {
    return axios.get<DatasetWithDatapoints[]>('datasets/withdatapoints')
  },
  createExistingDatapoint: (datasetRef: string, datapointRef: string) => {
    return axios.post(`/dataset/${datasetRef}/datapoint/${datapointRef}`, { active_alias_id: null })
  },
  createNewDatapoint: (datasetRef: string, body: DatapointPayload) => {
    return axios.post(`/dataset/${datasetRef}/datapoint`, body)
  },
  editDatapoint: (datapointRef: string, body: DatapointPayload) => {
    return axios.patch(`/datapoint/${datapointRef}`, body)
  },
  getDatapointOptions: async (datasetRef: string) => {
    const response = await axios.get<DatapointOptionsResponse>(`/dataset/${datasetRef}/datapoint/options`)

    response.data.calculations.sort((a, b) => a.calc_name.localeCompare(b.calc_name))

    return response
  },
  getSystemClassifications: () => {
    return axios.get<Classification[]>(`/classifications/system`)
  },
  getClassifications: (datasetRef: string) => {
    return axios.get<Classification[]>(`/dataset/${datasetRef}/classifications`)
  },
  getClassification: (classificationId: string | number) => {
    return axios.get<Classification>(`/classification/${classificationId}`)
  },
  createClassification: (datasetRef: string, payload: ClassificationPayload) => {
    return axios.post(`/dataset/${datasetRef}/classification`, payload)
  },
  updateClassification: (classificationId: string | number, payload: ClassificationPayload) => {
    return axios.patch(`/classification/${classificationId}`, payload)
  },
  deleteClassification: (classificationId: string | number) => {
    return axios.delete(`/classification/${classificationId}`)
  },
  createClassificationValue: (classificationId: string | number, payload: ClassificationValuePayload) => {
    return axios.post(`/classification/${classificationId}/value`, payload)
  },
  updateClassificationValue: (
    classificationId: string | number,
    valueId: string | number,
    payload: ClassificationValuePayload
  ) => {
    return axios.patch(`/classification/${classificationId}/value/${valueId}`, payload)
  },
  deleteClassificationValue: (classificationId: string | number, valueId: string | number) => {
    return axios.delete(`/classification/${classificationId}/value/${valueId}`)
  },
  reorderClassificationValues: (classificationId: string | number, valueIds: number[]) => {
    return axios.post(`/classification/${classificationId}/reorder`, valueIds)
  },
  addUserToGroup: (groupRef: string, userRef: string, isGroupAdmin: boolean) => {
    return axios.post(`/group/${groupRef}/users/${userRef}`, { is_group_admin: isGroupAdmin })
  },
  updateUserGroupStatus: (groupRef: string, userRef: string, isGroupAdmin: boolean) => {
    return axios.patch(`/group/${groupRef}/users/${userRef}`, { is_group_admin: isGroupAdmin })
  },
  removeUserFromGroup: (groupRef: string, userRef: string) => {
    return axios.delete(`/group/${groupRef}/users/${userRef}`)
  },
  getGroupsWithMembers: () => {
    return axios.get<GroupsWithMembers>('/tenant/current/groups/nonsystem/withmembers')
  },
  getCurrentDatasets: (groupRef: string) => {
    return axios.get<UserDetailsDataset[]>(`/group/${groupRef}/datasets/current`)
  },
  getAvailableDatasets: (groupRef: string) => {
    return axios.get<UserDetailsDataset[]>(`/group/${groupRef}/datasets/available`)
  },
  addDataset: (groupRef: string, datasetRef: string, expiry: string | null) => {
    return axios.post(`/dataset/${datasetRef}/groups`, [{ group_ref: groupRef, expiry: expiry }])
  },
  updateDataset: (groupRef: string, datasetRef: string, expiry: string | null) => {
    return axios.patch(`/dataset/${datasetRef}/groups`, [{ group_ref: groupRef, expiry: expiry }])
  },
  removeDataset: (groupRef: string, datasetRef: string) => {
    return axios.delete(`/dataset/${datasetRef}/groups`, { data: [groupRef] })
  },
  getSecurityMasterNavigator: () => {
    return axios.get<SecurityMasterNavigator>('/static/navigator')
  },
  getAssetStatic: (assetType: string, datasetRef: string | null) => {
    const params = []
    if (datasetRef) {
      params.push(`dataset=${datasetRef}`)
    }
    return axios.get<StaticResponse>(`/asset_type/${assetType}/static?${params.join('&')}`)
  },
  getAssetStaticOptions: (assetType: string) => {
    return axios.get<StaticOptionsResponse>(`/asset_type/${assetType}/options`)
  },
  updateAssetDatapoint: (assetRef: string, datapointRef: string, value: EditDatapointValue, asOnDate?: Date) => {
    const params = []
    if (asOnDate) {
      params.push(`as_on=${asOnDate}`)
    }

    return axios.patch(`/asset/${assetRef}/datapoint/${datapointRef}?${params.join('&')}`, value)
  },
  getAssetImports: () => {
    return axios.get<AssetImport[]>('/assets/imports')
  },
  requestAssetImport: async (payload: RequestAssetImportPayload) => {
    return axios.post<{ import_pending: boolean }>(`/assets/imports`, payload)
  },
  completeAssetImport: async (importId: number) => {
    return axios.post<{ imported: boolean }>(`/assets/imports/${importId}/complete`)
  },
  requestAssetsSync: async () => {
    return axios.post<{ import_pending: boolean }>(`/assets/imports/sync`)
  },
  getDatapointHistory: (assetRef: string, datapointRef: string, datasetRef?: string) => {
    const params = []
    if (datasetRef) {
      params.push(`dataset=${datasetRef}`)
    }

    return axios.get<DatapointHistory>(`/asset/${assetRef}/datapoint/${datapointRef}/historical?${params.join('&')}`)
  },
  staticUploadGetOptions: (datasetRef?: string) => {
    let params = ''
    if (datasetRef) {
      params = `?dataset=${datasetRef}`
    }
    return axios.get<StaticUploadOptionsResponse>(`/upload_static/options${params}`)
  },
  staticUploadInspectFile: (file: File) => {
    const form = composeFileForm(file)

    return axios.post<StaticUploadInspection>(`/upload_static/inspect?rows=5`, form)
  },
  staticUploadVerifyFile: (params: StaticUploadParams, file: File) => {
    const form = composeFileForm(file)
    const queryParams = composeStaticUploadQueryParams(params)

    return axios.post(`/upload_static/verify?${queryParams}`, form)
  },
  staticUploadCommitFile: (params: StaticUploadParams, file: File) => {
    const form = composeFileForm(file)
    const queryParams = composeStaticUploadQueryParams(params)

    return axios.post(`/upload_static?${queryParams}`, form)
  },
  portfolioUploadGetOptions: () => {
    return axios.get<PortfolioUploadOptions>('/portfolio/upload/options')
  },
  portfolioUploadVerifyFile: (params: PortfolioUploadParams, file: File) => {
    const form = composeFileForm(file)
    const queryParams = composePortfolioUploadQueryParams(params)

    return axios.post(`/portfolio/${params.portfolioRef}/upload/verify?${queryParams}`, form)
  },
  portfolioUploadCommitFile: (params: PortfolioUploadParams, file: File) => {
    const form = composeFileForm(file)
    const queryParams = composePortfolioUploadQueryParams(params)

    return axios.post(`/portfolio/${params.portfolioRef}/upload?${queryParams}`, form)
  },
  getClassificationValues: (classificationId: number) => {
    return axios.get<ClassificationValue[]>(`/classification/${classificationId}/values`)
  },
  getRegularPortfolios: () => {
    return axios.get<RegularPortfolio[]>('/portfolios/regular')
  },
  getSandboxPortfolios: () => {
    return axios.get<SandboxPortfolio[]>('/portfolios/sandboxes')
  },
  getRegularPortfolioDetails: (portfolioRef: string) => {
    return axios.get<RegularPortfolio>(`/portfolio/${portfolioRef}`)
  },
  createPortfolio: (body: PortfolioPayload) => {
    return axios.post(`/portfolios`, body)
  },
  editPortfolio: (body: PortfolioPayload, portfolioRef: string) => {
    return axios.patch(`/portfolios/${portfolioRef}`, body)
  },
  getPortfolioOptions: () => {
    return axios.get<PortfolioOptions>('/portfolio/options')
  },
  deletePortfolio: (portfolioRef: string) => {
    return axios.delete(`/portfolio/${portfolioRef}`)
  },
  getAuditNavigator: () => {
    return axios.get<AuditNavigator>(`/audit/navigator`)
  },
  getAuditLogs: (filters: AuditFilters) => {
    const params = []

    if (filters.from) {
      params.push(`from_date=${filters.from}`)
    }

    if (filters.to) {
      params.push(`to_date=${filters.to}`)
    }

    if (filters.userRef) {
      params.push(`user_ref=${filters.userRef}`)
    }

    if (filters.auditType) {
      params.push(`audit_type=${filters.auditType}`)
    }

    return axios.get<AuditLog[]>(`/audit?${params.join('&')}`)
  },
  getLiquidityNavigator: () => {
    return axios.get<LiquidityNavigator>('/liquidity/navigator')
  },
  getLiquidityMonitor: (payload: LiquidityMonitorPayload) => {
    return axios.post<LiquidityMonitor>('/liquidity/monitor', payload)
  },
  getBankAccountsNavigator: async () => {
    return axios.get<BankAccountsNavigator>('/bank-accounts/navigator')
  },
  getBankAccountsCounterparties: async () => {
    return axios.get<CounterpartyAccountHeader[]>('/bank-accounts/counterparties')
  },
  getCounterpartyUnsettleDeals: async (bankAccountId: string, counterpartyId: number) => {
    return axios.get<BankAccountsUnsettledDeal[]>(
      `/bank-accounts/${bankAccountId}/counterparties/${counterpartyId}/deals/unsettled`
    )
  },
  insertCounterpartySettlement: async (
    bankAccountId: string,
    counterpartyId: number,
    payload: InsertCounterpartySettlementPayload
  ) => {
    return axios.post(`/bank-accounts/${bankAccountId}/counterparties/${counterpartyId}/settlements`, payload)
  },
  insertCustodianTransaction: async (bankAccountId: string, payload: InsertCustodianTransactionPayload) => {
    return axios.post(`/bank-accounts/${bankAccountId}/transactions`, payload)
  },
  getCustodianTransaction: async (bankAccountId: string, txnRef: string) => {
    return axios.get<CustodianTransactionResponse>(`/bank-accounts/${bankAccountId}/transactions/${txnRef}`)
  },
  getCounterpartySettlement: async (bankAccountId: string, txnRef: string) => {
    return axios.get<CounterpartySettlement>(`/bank-accounts/${bankAccountId}/settlements/${txnRef}`)
  },
  correctCustodianTransaction: async (
    bankAccountId: string,
    txnRef: string,
    payload: CorrectCustodianTransactionPayload
  ) => {
    return axios.post(`/bank-accounts/${bankAccountId}/transactions/${txnRef}`, payload)
  },
  correctCounterpartySettlement: async (
    bankAccountId: string,
    txnRef: string,
    payload: CorrectCounterpartySettlementPayload
  ) => {
    return axios.post(`/bank-accounts/${bankAccountId}/settlements/${txnRef}`, payload)
  },
  cancelBankAccountTransaction: async (bankAccountId: string, txnRef: string) => {
    return axios.delete(`/bank-accounts/${bankAccountId}/transactions/${txnRef}`)
  },
  getReconciliationGrid: async (bankAccountId: string) => {
    return axios.post<ReconGridResponse>(`/bank-accounts/${bankAccountId}/reconciliation/grid`)
  },
  reconcileTransactions: async (bankAccountId: string, payload: ReconcileTxnsPayload) => {
    return axios.post(`/bank-accounts/${bankAccountId}/reconciliation/reconcile`, payload)
  },
  unreconcileRecord: async (bankAccountId: string, recordId: number) => {
    return axios.post(`/bank-accounts/${bankAccountId}/records/${recordId}/unreconcile`)
  },
  bankRecordsUploadGetOptions: () => {
    return axios.get<RecordsUploadOptionsResponse>('/bank-accounts/records/uploads/options')
  },
  bankRecordsUploadInspectFile: (entityId: number, accountId: number | null, file: File) => {
    const form = composeFileForm(file)

    let url = `/bank-accounts/records/uploads/inspect?number_of_rows=6&related_entity_id=${entityId}`
    if (accountId) {
      url += `&account_id=${accountId}`
    }

    return axios.post<RecordsUploadInspectResponse>(url, form)
  },
  bankRecordsUploadVerifyFile: (params: BankRecordsUploadParams, file: File) => {
    const form = composeFileForm(file)
    const queryParams = composeBankRecordsUploadQueryParams(params)
    const url = `/bank-accounts/records/uploads/verify?${queryParams}`

    return axios.post<RecordsUploadVerifyResponse>(url, form)
  },
  bankRecordsUploadCommitFile: (params: BankRecordsUploadParams, file: File) => {
    const form = composeFileForm(file)
    const queryParams = composeBankRecordsUploadQueryParams(params)
    const url = `/bank-accounts/records/uploads/commit?${queryParams}`

    return axios.post<RecordsUploadCommitResponse>(url, form)
  },
  addGroup: (payload: AddGroupPayload) => {
    return axios.post(`/group`, payload)
  },
  getGroupDetails: (groupRef: string) => {
    return axios.get<GroupWithMembers>(`/group/${groupRef}/withmembers`)
  },
  deleteGroup: (groupRef: string) => {
    return axios.delete(`/group/${groupRef}`)
  },
  updateGroup: (payload: AddGroupPayload, groupRef: string) => {
    return axios.patch(`/group/${groupRef}`, payload)
  },
  addTenant: (payload: AddTenantPayload) => {
    return axios.post('/tenant', payload)
  },
  getModels: (datasetRef: string) => {
    return axios.get<Model[]>(`/dataset/${datasetRef}/models`)
  },
  deleteModel: (modelRef: string) => {
    return axios.delete(`/model/${modelRef}`)
  },
  addModel: (datasetRef: string, payload: AddModelPayload) => {
    return axios.post(`/dataset/${datasetRef}/model`, payload)
  },
  getModel: (modelRef: string, version: string | null) => {
    const param = version ? `?version=${version}` : ''
    return axios.get<ModelDetails>(`/model/${modelRef}${param}`)
  },
  uploadModel: (modelRef: string, date: string, file: File) => {
    const form = composeFileForm(file)

    return axios.post(`/model/${modelRef}/upload?datetime=${date}`, form)
  },
  exportPortfolio: async (params: ExportPortfolioParams): Promise<AxiosResponse<ExportPortfolioData>> => {
    try {
      const queryParams = []

      if (params.viewRef) {
        queryParams.push(`view_ref=${params.viewRef}`)
      }
      if (params.exportTransactions) {
        queryParams.push('txns')
      }

      const url = `/portfolio/${params.portfolioRef}/txnexport?${queryParams.join('&')}`
      const response = await axios.get(url, { responseType: 'blob' })

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
      })

      let filename = params.portfolioName
      if (params.viewName) {
        filename += ` (${params.viewName})`
      }

      const date = new Date()
      filename += ` - ${format(date, 'yyyy.MM.dd')}_${format(date, 'HH.mm.ss')}.xlsx`

      response.data = { blob, filename }

      return response
    } catch (error: any) {
      error.response.data = await parseBlobToJson(error)
      throw error
    }
  },
  getPricing: async (payload: PriceUpPayload) => {
    const response = await axios.post<{ '0': TradeParticulars }>(`/txn_terms/price_up`, { '0': payload })
    return response.data[0]
  },
  generateDatadoc(datasetRef: string, payload: GenerateDatatocPayload[]) {
    return axios.post<GenerateDatatocResponse[]>(`/assets/autogenerate?dataset=${datasetRef}`, payload)
  },
}

function composeStaticUploadQueryParams(params: StaticUploadParams) {
  const datapoints = params.datapoints.slice(1 + Number(params.useDate), params.datapoints.length).join(',')
  const useDate = params.useDate ? 'true' : 'false'

  const queryParams = [
    `datapoints=${datapoints}`,
    `use_date=${useDate}`,
    params.createConstituents ? `create_constituents=true` : null,
    params.datasetRef ? `dataset=${params.datasetRef}` : null,
  ]
    .filter(Boolean)
    .join('&')

  return queryParams
}

function composeBankRecordsUploadQueryParams(params: BankRecordsUploadParams) {
  const queryParams = [
    `header_row=${params.headerRow}`,
    `skip_after_header_rows=${params.skipAfterHeaderRows}`,
    `skip_last_rows=${params.skipLastRows}`,
    `columns=${params.columns.join(',')}`,
    params.accountId ? `account_id=${params.accountId}` : null,
  ]
    .filter(Boolean)
    .join('&')

  return queryParams
}

function composePortfolioUploadQueryParams(params: PortfolioUploadParams) {
  const columns = params.columns.join(',')
  const uploadingTransactions = params.uploadingTransactions ? 'uploading_txns=true' : ''

  const queryParams = [`columns=${columns}`, uploadingTransactions].filter(Boolean).join('&')

  return queryParams
}

function updateGridViewRequestColumns(columns: GridDataViewCreateBody['view_options']['columns']) {
  // save panel and hidden columns at the end
  // this way when they are added back on the grid they show at the end
  // but when manipulating the grid they still show in the same place
  if (columns) {
    const main = columns.filter((column) => column.position === 'm')
    const panel = columns.filter((column) => column.position === 'p')
    const hidden = columns.filter((column) => column.position === 'h')
    return [...main, ...panel, ...hidden]
  }
  return columns
}

async function parseBlobToJson(error: AxiosError | null) {
  const file = new Blob([error?.response?.data as BlobPart], { type: 'application/json' })
  return JSON.parse(await file.text())
}

function composeFileForm(file: File): FormData {
  const form = new FormData()
  form.append('file', file)
  return form
}

export default api
