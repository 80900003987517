import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'

type FilterFormButtonsProps = {
  submitDisabled?: boolean
  clearDisabled: boolean
  onCancel: () => void
  onClear?: () => void
}

function FilterFormButtons(props: FilterFormButtonsProps) {
  const { submitDisabled, clearDisabled, onCancel, onClear } = props

  const { t } = useTranslation()

  return (
    <Stack direction="row" justifyContent="flex-end" py={1} px={3} gap={1}>
      {onClear && (
        <Button variant="text" onClick={onClear} disabled={clearDisabled} sx={{ fontSize: 13, mr: 'auto' }}>
          {t('common:clear')}
        </Button>
      )}
      <Button variant="text" onClick={onCancel} sx={{ fontSize: 13 }}>
        {t('common:cancel')}
      </Button>
      <Button type="submit" variant="contained" disabled={submitDisabled} sx={{ fontSize: 13 }}>
        {t('common:apply')}
      </Button>
    </Stack>
  )
}

export default FilterFormButtons
