import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export function useQueryParam(key: string) {
  const [searchParams, setSearchParams] = useSearchParams()

  const value = searchParams.get(key)
  const isSet = value !== null

  const setFn = useCallback(
    (newValue: string) => {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set(key, newValue)
      setSearchParams(newSearchParams)
    },
    [searchParams, setSearchParams]
  )

  const toggleFn = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams)
    if (newSearchParams.has(key)) {
      newSearchParams.delete(key)
    } else {
      newSearchParams.set(key, 'true')
    }
    setSearchParams(newSearchParams)
  }, [searchParams, setSearchParams])

  const deleteFn = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete(key)
    setSearchParams(newSearchParams)
  }, [searchParams, setSearchParams])

  return { value, isSet, set: setFn, toggle: toggleFn, delete: deleteFn }
}
