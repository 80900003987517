import { useCallback, useState } from 'react'

function useOpenStateWithData<T>() {
  const [data, setData] = useState<T | null>(null)

  const isOpen = !!data

  const open = useCallback((data: T) => setData(data), [])

  const close = useCallback(() => setData(null), [])

  const toggle = useCallback((data: T) => setData((prev) => (prev ? null : data)), [])

  return { isOpen, data, open, close, toggle }
}

export default useOpenStateWithData
