import { ArrowDownward, ArrowUpward, FilterAlt, MoreVert } from '@mui/icons-material'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { MouseEvent, useRef } from 'react'
import { DeleteFilterPayload } from '../../modules/portfolios/data/use-view-config-state'
import { GridDataFilterOption, ParsedFilter } from '../../services/data/filter-parsing'
import { ParsedGridDataHeading } from '../../services/data/grid-data-parsing'
import { GridDataViewFilters, GridDataViewSortBy } from '../../services/data/types/grid-data-view'
import useOpenState from '../../utils/hooks/use-open-state'
import useOpenStateWithData from '../../utils/hooks/use-open-state-with-data'
import FilterMenuOptions from '../filters/filter-menu-options'
import ColumnHeaderEditDecimalsModal from './column-header-edit-decimals-modal'
import ColumnHeaderEditNameModal from './column-header-edit-name-modal'
import ColumnHeaderMenu from './column-header-menu'

type ColumnHeaderProps = {
  heading: ParsedGridDataHeading
  aggregations: string[]
  sortBy: GridDataViewSortBy | null
  filterOptions: GridDataFilterOption[]
  selectedFilters: ParsedFilter[]
  isGridsOnlyUser: boolean
  onSortChange: (sortBy: GridDataViewSortBy | null) => void
  onMoveColumn: (fromDatapointRef: string, toDatapointRef: string) => void
  onHideColumn: (datapointRef: string) => void
  onMoveToPanel?: (datapointRef: string) => void
  onRemoveFromPanel?: (datapointRef: string) => void
  onUpdateColumn?: (
    datapointRef: string,
    name: string | undefined,
    decimalPlaces: number | undefined,
    displayRaw: boolean | undefined
  ) => void
  onGroupBy?: (datapointRef: string) => void
  onAddFilters: (filters: GridDataViewFilters) => void
  onDeleteFilter: (payload: DeleteFilterPayload) => void
}

export function ColumnHeader(props: ColumnHeaderProps) {
  const {
    heading,
    aggregations,
    sortBy,
    filterOptions,
    selectedFilters,
    isGridsOnlyUser,
    onSortChange,
    onMoveColumn,
    onHideColumn,
    onMoveToPanel,
    onRemoveFromPanel,
    onUpdateColumn,
    onGroupBy,
    onAddFilters,
    onDeleteFilter,
  } = props

  const outer = useRef(null)
  const menuButton = useOpenState({ open: false })
  const columnMenu = useOpenState()
  const filterMenu = useOpenStateWithData<HTMLElement>()
  const nameModal = useOpenState()
  const decimalsModal = useOpenState()

  const datapointRef = heading.meta.datapoint_ref
  const hasFilters = selectedFilters.some((filter) => filter.datapointRef === datapointRef)
  const isSortVisible = sortBy?.column === datapointRef
  const isGroupByDisabled = aggregations.some((agg) => agg === datapointRef)
  const canReorder = [null, 'current'].includes(heading.panelType)
  const showMenuButton = menuButton.isOpen || columnMenu.isOpen

  function handleFilterMenuToggle(event: MouseEvent<HTMLElement>) {
    filterMenu.toggle(event.currentTarget)
  }

  function handleCloseMenus() {
    columnMenu.close()
    filterMenu.close()
  }

  return (
    <>
      <div
        ref={outer}
        key={datapointRef}
        draggable={canReorder}
        onDragStart={(event) => {
          event.dataTransfer.setData('text/plain', datapointRef)

          setTimeout(() => {
            const target = event.target as any
            target.classList.add('dragging-me')
          }, 0)
        }}
        onDragEnter={(event) => {
          event.preventDefault()
          event.currentTarget.classList.add('dragging-over')
        }}
        onDragOver={(event) => {
          event.preventDefault()
          event.currentTarget.classList.add('dragging-over')
        }}
        onDragLeave={(event) => {
          event.preventDefault()
          event.currentTarget.classList.remove('dragging-over')
        }}
        onDrop={(event) => {
          event.preventDefault()
          const draggedDatapointRef = event.dataTransfer.getData('text/plain')
          event.currentTarget.classList.remove('dragging-over')

          onMoveColumn(draggedDatapointRef, datapointRef)
        }}
        onDragEnd={(event) => {
          const target = event.target as any
          target.classList.remove('dragging-me')
        }}
        onMouseOver={menuButton.open}
        onMouseLeave={menuButton.close}
        onContextMenu={(event) => {
          event.preventDefault()
          columnMenu.toggle()
        }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', mr: 'auto' }}>
          <Tooltip title={heading.meta.datapoint_name} arrow disableInteractive placement="top" enterDelay={800}>
            <Typography fontSize="14px" fontWeight="500">
              {heading.title}
            </Typography>
          </Tooltip>

          {hasFilters && (
            <IconButton
              size="small"
              onClick={handleFilterMenuToggle}
              sx={{
                background: 'var(--af-header-background-color)',
                ':hover': {
                  background: 'var(--af-header-background-color)',
                },
              }}
            >
              <FilterAlt fontSize="small" sx={{ color: 'gray.300' }} />
            </IconButton>
          )}

          {isSortVisible && (
            <IconButton
              size="small"
              onClick={() => {
                const newDirection = sortBy?.direction === 'asc' ? 'dsc' : 'asc'
                onSortChange({ column: datapointRef, direction: newDirection })
              }}
              sx={{
                color: 'gray.300',
                background: 'var(--af-header-background-color)',
                ':hover': {
                  background: 'var(--af-header-background-color)',
                },
              }}
            >
              {sortBy.direction === 'asc' && <ArrowDownward fontSize="small" />}
              {sortBy.direction === 'dsc' && <ArrowUpward fontSize="small" />}
            </IconButton>
          )}
        </Stack>

        <div style={{ width: '15px', height: '10px' }} />

        {showMenuButton && (
          <IconButton
            size="small"
            onClick={columnMenu.toggle}
            sx={{
              color: 'gray.300',
              background: 'var(--af-header-background-color)',
              ':hover': {
                background: 'var(--af-header-background-color)',
              },
            }}
          >
            <MoreVert fontSize="small" />
          </IconButton>
        )}
      </div>

      {columnMenu.isOpen && (
        <ColumnHeaderMenu
          heading={heading}
          sortBy={sortBy}
          isGroupByDisabled={isGroupByDisabled}
          anchorEl={outer.current}
          isFilterMenuOpen={filterMenu.isOpen}
          filterOptions={filterOptions}
          onCloseMenu={handleCloseMenus}
          onHideColumn={() => onHideColumn(datapointRef)}
          onFilterByColumn={handleFilterMenuToggle}
          onMoveToPanel={
            onMoveToPanel
              ? () => {
                  onMoveToPanel?.(datapointRef)
                  handleCloseMenus()
                }
              : undefined
          }
          onRemoveFromPanel={
            onRemoveFromPanel
              ? () => {
                  onRemoveFromPanel?.(datapointRef)
                  handleCloseMenus()
                }
              : undefined
          }
          onRenameColumn={
            onUpdateColumn
              ? () => {
                  nameModal.open()
                  handleCloseMenus()
                }
              : undefined
          }
          onUpdateDecimals={
            onUpdateColumn
              ? () => {
                  decimalsModal.open()
                  handleCloseMenus()
                }
              : undefined
          }
          onDisplayRaw={() => {
            onUpdateColumn?.(datapointRef, heading.title || undefined, heading.decimalPlaces, !heading.displayRaw)
            handleCloseMenus()
          }}
          onGroupByCol={() => {
            onGroupBy?.(datapointRef)
            handleCloseMenus()
          }}
          onSortAsc={() => {
            onSortChange({ column: datapointRef, direction: 'asc' })
            handleCloseMenus()
          }}
          onSortDesc={() => {
            onSortChange({ column: datapointRef, direction: 'dsc' })
            handleCloseMenus()
          }}
          onSortReset={() => {
            onSortChange(null)
            handleCloseMenus()
          }}
        />
      )}

      <ColumnHeaderEditNameModal
        open={nameModal.isOpen}
        title={heading.title}
        datapointName={heading.meta.datapoint_name}
        onSave={(name) => {
          onUpdateColumn?.(datapointRef, name, heading.decimalPlaces, heading.displayRaw)
        }}
        onClose={nameModal.close}
      />
      <ColumnHeaderEditDecimalsModal
        open={decimalsModal.isOpen}
        decimalPlaces={heading.decimalPlaces}
        onSave={(decimalPlaces) => {
          onUpdateColumn?.(datapointRef, heading.title || undefined, decimalPlaces, heading.displayRaw)
        }}
        onClose={decimalsModal.close}
      />

      {filterMenu.isOpen && (
        <FilterMenuOptions
          anchorEl={filterMenu.data}
          filterOptions={filterOptions}
          selectedFilters={selectedFilters}
          activeMenuDatapointRef={datapointRef}
          disableBackdrop={columnMenu.isOpen}
          onChange={(payload) => {
            onAddFilters(payload)
            handleCloseMenus()
          }}
          onDelete={(payload) => {
            onDeleteFilter(payload)
            handleCloseMenus()
          }}
          onClose={filterMenu.close}
        />
      )}
    </>
  )
}
