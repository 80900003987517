import { useEffect, useReducer, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FilterValue } from '../../../services/data/filter-parsing'
import { StaticHeading } from '../../../services/data/types/asset-static-data'
import { GridResponseHeading } from '../../../services/data/types/grid-data'
import { ViewDataOptions } from '../../../services/data/types/grid-data-options'
import { GridDataViewFilters, GridDataViewSortBy, GridViewColumn } from '../../../services/data/types/grid-data-view'
import { UamPanelMode } from '../../../services/data/types/grid-panels'

export type ViewConfigState = {
  viewRef: string | null
  datasetRef: string | null
  aggregations: string[]
  panelMode: UamPanelMode | null
  filters: GridDataViewFilters | null
  columns: GridViewColumn[] | null
  sortBy: GridDataViewSortBy | null
  asOfDate: string | null
  isViewDirty: boolean
}

type ViewGridDataHeadings = GridResponseHeading[] | StaticHeading[]

const initialViewConfigState: ViewConfigState = {
  viewRef: null,
  datasetRef: null,
  aggregations: [],
  panelMode: null,
  filters: null,
  columns: null,
  sortBy: null,
  asOfDate: null,
  isViewDirty: false,
}

function useViewConfigState(options?: ViewDataOptions) {
  const [viewConfigState, dispatch] = useReducer(viewConfigReducer, initialViewConfigState)
  const [isStateReady, setIsStateReady] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const viewRef = searchParams.get('viewRef')

  useEffect(() => {
    if (viewRef) {
      setView(viewRef)
      return
    }

    if (options?.default_view_ref) {
      setView(options.default_view_ref)
      return
    }

    // make sure we have either a view or a dataset selected
    // if no view or dataset can be set, some configuration is missing
    if (!viewRef && options && !options.default_view_ref) {
      // this happens when the portfolio doesn't have a default view
      const firstView = options.views[0]
      if (firstView) {
        setView(firstView.view_ref)
        return
      }

      // this happens when the portfolio doesn't have a default view,
      // and there are no views (specially on transactions grid)
      const firstDataset = options.datasets[0]
      if (firstDataset) {
        setDataset(firstDataset.dataset_ref)
        return
      }
    }
  }, [options, viewRef])

  useEffect(() => {
    if (options?.views && !isStateReady) {
      setIsStateReady(true)
    }
  }, [options, isStateReady])

  function setAggregations(aggregations: string[]) {
    dispatch({ type: 'SET_AGGREGATIONS', payload: aggregations })
  }

  function toggleHiddenColumn(datapointRef: string, originalColumns: ViewGridDataHeadings) {
    dispatch({ type: 'TOGGLE_HIDDEN_COLUMN', payload: { datapointRef, originalColumns } })
  }

  function setHiddenColumns(hiddenColumns: string[]) {
    dispatch({ type: 'SET_HIDDEN_COLUMNS', payload: hiddenColumns })
  }

  function hideAllColumns() {
    dispatch({ type: 'HIDE_ALL_COLUMNS' })
  }

  function unhideAllColumns() {
    dispatch({ type: 'UNHIDE_ALL_COLUMNS' })
  }

  function moveColumn(fromDatapointRef: string, toDatapointRef: string, originalColumns: ViewGridDataHeadings) {
    dispatch({ type: 'MOVE_COLUMN', payload: { fromDatapointRef, toDatapointRef, originalColumns } })
  }

  function moveColumnToPanel(datapointRef: string, originalColumns: GridResponseHeading[]) {
    dispatch({ type: 'MOVE_COLUMN_TO_PANEL', payload: { datapointRef, originalColumns } })
  }

  function removeColumnFromPanel(datapointRef: string, originalColumns: GridResponseHeading[]) {
    dispatch({ type: 'REMOVE_COLUMN_FROM_PANEL', payload: { datapointRef, originalColumns } })
  }

  function setColumnWidth(datapointRef: string, width: number, originalColumns: ViewGridDataHeadings) {
    dispatch({ type: 'SET_COLUMN_WIDTH', payload: { datapointRef, width, originalColumns } })
  }

  function updateColumn(
    datapointRef: string,
    name: string | undefined,
    decimalPlaces: number | undefined,
    displayRaw: boolean | undefined,
    originalColumns: GridResponseHeading[] | StaticHeading[]
  ) {
    dispatch({ type: 'UPDATE_COLUMN', payload: { datapointRef, name, decimalPlaces, displayRaw, originalColumns } })
  }

  function setPanelMode(panelMode: UamPanelMode | null) {
    dispatch({ type: 'SET_PANEL_MODE', payload: panelMode })
  }

  function setFilters(filters: GridDataViewFilters | null) {
    dispatch({ type: 'SET_FILTERS', payload: filters })
  }

  function addFilters(filters: GridDataViewFilters) {
    dispatch({ type: 'ADD_FILTERS', payload: filters })
  }

  function deleteFilter(payload: DeleteFilterAction['payload']) {
    dispatch({ type: 'DELETE_FILTER', payload })
  }

  function setSortBy(sortBy: GridDataViewSortBy | null) {
    dispatch({ type: 'SET_SORT_BY', payload: sortBy })
  }

  function setAsOfDate(asOfDate: SetAsOfDateAction['payload'] | null) {
    dispatch({ type: 'SET_AS_OF_DATE', payload: asOfDate })
  }

  function setView(viewRef: string) {
    const selectedView = options?.views?.find((view) => view.view_ref === viewRef)

    if (selectedView) {
      dispatch({
        type: 'SET_VIEW_CONFIG',
        payload: {
          viewRef: selectedView.view_ref,
          datasetRef: selectedView.dataset_ref,
          aggregations: selectedView.view_options.aggregation,
          panelMode: selectedView.view_options.panel_mode,
          columns: selectedView.view_options.columns,
          filters: selectedView.view_options.filters,
          sortBy: selectedView.view_options.sort_by,
          asOfDate: selectedView.view_options.as_of_date,
          isViewDirty: false,
        },
      })
      searchParams.set('viewRef', viewRef)
      setSearchParams(searchParams)
    }
  }

  function setDataset(datasetRef: string) {
    dispatch({ type: 'SET_VIEW_CONFIG', payload: { ...initialViewConfigState, datasetRef } })
  }

  function resetState() {
    dispatch({ type: 'SET_VIEW_CONFIG', payload: initialViewConfigState })
  }

  return {
    viewConfigState,
    setAggregations,
    setHiddenColumns,
    hideAllColumns,
    unhideAllColumns,
    toggleHiddenColumn,
    moveColumn,
    moveColumnToPanel,
    removeColumnFromPanel,
    setColumnWidth,
    updateColumn,
    setPanelMode,
    setFilters,
    addFilters,
    deleteFilter,
    setSortBy,
    setAsOfDate,
    setView,
    setDataset,
    resetState,
    isStateReady,
  }
}

export default useViewConfigState

type SetAggregationsAction = {
  type: 'SET_AGGREGATIONS'
  payload: string[]
}

type SetHiddenColumnsAction = {
  type: 'SET_HIDDEN_COLUMNS'
  payload: string[]
}

type HideAllColumnsAction = {
  type: 'HIDE_ALL_COLUMNS'
}

type UnhideAllColumnsAction = {
  type: 'UNHIDE_ALL_COLUMNS'
}

type ToggleHiddenColumnAction = {
  type: 'TOGGLE_HIDDEN_COLUMN'
  payload: { datapointRef: string; originalColumns: ViewGridDataHeadings }
}

type MoveColumnAction = {
  type: 'MOVE_COLUMN'
  payload: { fromDatapointRef: string; toDatapointRef: string; originalColumns: ViewGridDataHeadings }
}

type MoveColumnToPanelAction = {
  type: 'MOVE_COLUMN_TO_PANEL'
  payload: { datapointRef: string; originalColumns: GridResponseHeading[] }
}

type RemoveColumnFromPanelAction = {
  type: 'REMOVE_COLUMN_FROM_PANEL'
  payload: { datapointRef: string; originalColumns: GridResponseHeading[] }
}

type SetColumnWidth = {
  type: 'SET_COLUMN_WIDTH'
  payload: { datapointRef: string; width: number; originalColumns: ViewGridDataHeadings }
}

type UpdateColumn = {
  type: 'UPDATE_COLUMN'
  payload: {
    datapointRef: string
    name: string | undefined
    decimalPlaces: number | undefined
    displayRaw: boolean | undefined
    originalColumns: GridResponseHeading[] | StaticHeading[]
  }
}

type SetPanelModeAction = {
  type: 'SET_PANEL_MODE'
  payload: UamPanelMode | null
}

type SetFiltersAction = {
  type: 'SET_FILTERS'
  payload: GridDataViewFilters | null
}

type AddFiltersAction = {
  type: 'ADD_FILTERS'
  payload: GridDataViewFilters
}

type DeleteFilterAction = {
  type: 'DELETE_FILTER'
  payload: {
    datapointRef: string
    singleValue: FilterValue | null
  }
}
export type DeleteFilterPayload = DeleteFilterAction['payload']

type SetSortByAction = {
  type: 'SET_SORT_BY'
  payload: GridDataViewSortBy | null
}

type SetViewAction = {
  type: 'SET_VIEW_CONFIG'
  payload: ViewConfigState
}

type SetAsOfDateAction = {
  type: 'SET_AS_OF_DATE'
  payload: string | null
}

type Action =
  | SetAggregationsAction
  | SetHiddenColumnsAction
  | HideAllColumnsAction
  | UnhideAllColumnsAction
  | ToggleHiddenColumnAction
  | MoveColumnAction
  | MoveColumnToPanelAction
  | RemoveColumnFromPanelAction
  | SetColumnWidth
  | UpdateColumn
  | SetPanelModeAction
  | SetSortByAction
  | SetViewAction
  | SetFiltersAction
  | AddFiltersAction
  | DeleteFilterAction
  | SetAsOfDateAction

function viewConfigReducer(state: ViewConfigState, action: Action): ViewConfigState {
  switch (action.type) {
    case 'SET_AGGREGATIONS':
      return {
        ...state,
        aggregations: action.payload,
        isViewDirty: true,
      }
    case 'TOGGLE_HIDDEN_COLUMN': {
      const columns = initColumnOrder(state.columns, action.payload.originalColumns)

      for (const column of columns) {
        if (column.datapoint_ref === action.payload.datapointRef) {
          column.position = column.position === 'h' ? 'm' : 'h'
          break
        }
      }

      return {
        ...state,
        isViewDirty: true,
        columns,
      }
    }
    case 'SET_HIDDEN_COLUMNS': {
      if (!state.columns) {
        return state
      }

      const newColumns: GridViewColumn[] = state.columns.map((column) => {
        const shouldBeHidden = action.payload.includes(column.datapoint_ref)
        const position = shouldBeHidden ? 'h' : column.position === 'h' ? 'm' : column.position

        return { ...column, position }
      })

      return {
        ...state,
        isViewDirty: true,
        columns: newColumns,
      }
    }
    case 'HIDE_ALL_COLUMNS':
      if (!state.columns) {
        return state
      }

      return {
        ...state,
        isViewDirty: true,
        columns: state.columns.map((column) => ({ ...column, position: 'h' })),
      }
    case 'UNHIDE_ALL_COLUMNS':
      if (!state.columns) {
        return state
      }

      return {
        ...state,
        isViewDirty: true,
        columns: state.columns.map((column) => ({ ...column, position: 'm' })),
      }
    case 'MOVE_COLUMN': {
      const columns = initColumnOrder(state.columns, action.payload.originalColumns)
      const newColumns = moveColumn(columns, action.payload.fromDatapointRef, action.payload.toDatapointRef)

      return {
        ...state,
        isViewDirty: true,
        columns: newColumns,
      }
    }
    case 'MOVE_COLUMN_TO_PANEL': {
      const columns = initColumnOrder(state.columns, action.payload.originalColumns)

      for (const column of columns) {
        if (column.datapoint_ref === action.payload.datapointRef) {
          column.position = 'p'
          break
        }
      }

      return {
        ...state,
        isViewDirty: true,
        columns,
      }
    }
    case 'REMOVE_COLUMN_FROM_PANEL': {
      const columns = initColumnOrder(state.columns, action.payload.originalColumns)

      for (const column of columns) {
        if (column.datapoint_ref === action.payload.datapointRef) {
          column.position = 'm'
          break
        }
      }

      return {
        ...state,
        isViewDirty: true,
        columns,
      }
    }
    case 'SET_COLUMN_WIDTH': {
      const columns = initColumnOrder(state.columns, action.payload.originalColumns)

      for (const column of columns) {
        if (column.datapoint_ref === action.payload.datapointRef) {
          column.width = action.payload.width
          break
        }
      }

      return {
        ...state,
        isViewDirty: true,
        columns,
      }
    }
    case 'UPDATE_COLUMN': {
      const columns = initColumnOrder(state.columns, action.payload.originalColumns)

      for (const column of columns) {
        if (column.datapoint_ref === action.payload.datapointRef) {
          column.name = action.payload.name || undefined
          column.decimal_places = action.payload.decimalPlaces ?? undefined
          column.display_raw = action.payload.displayRaw || undefined
          break
        }
      }

      return {
        ...state,
        isViewDirty: true,
        columns,
      }
    }
    case 'SET_PANEL_MODE':
      return {
        ...state,
        panelMode: action.payload,
        isViewDirty: true,
      }
    case 'SET_FILTERS':
      return {
        ...state,
        filters: action.payload,
        isViewDirty: true,
      }
    case 'ADD_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
        isViewDirty: true,
      }
    case 'DELETE_FILTER': {
      const datapointRef = action.payload.datapointRef
      const singleValue = action.payload.singleValue
      const nextFilters = state.filters ? { ...state.filters } : {}

      if (singleValue !== null) {
        const datapointFilter = nextFilters[datapointRef]
        if (datapointFilter) {
          datapointFilter.values = datapointFilter.values.filter((v) => v !== action.payload.singleValue)
          if (!datapointFilter.values.length) {
            delete nextFilters[datapointRef]
          }
        }
      } else {
        delete nextFilters[datapointRef]
      }

      const isEmpty = Object.values(nextFilters).length === 0
      const filters = isEmpty ? null : nextFilters

      return {
        ...state,
        filters,
        isViewDirty: true,
      }
    }
    case 'SET_SORT_BY':
      return {
        ...state,
        sortBy: action.payload,
        isViewDirty: true,
      }
    case 'SET_AS_OF_DATE':
      return {
        ...state,
        asOfDate: action.payload,
        isViewDirty: true,
      }
    case 'SET_VIEW_CONFIG':
      return action.payload
    default:
      return state
  }
}

export function initColumnOrder(
  savedColumnOrder: GridViewColumn[] | null,
  headings: GridResponseHeading[] | StaticHeading[]
): GridViewColumn[] {
  const positions = { maingrid: 'm', panel: 'p' } as const

  const gridColumnOrder = headings
    .filter((heading) => heading.position !== 'change')
    .map((heading) => {
      const position = positions[heading.position as Exclude<GridResponseHeading['position'], 'change'>] || 'm'
      const column: GridViewColumn = {
        datapoint_ref: heading.datapoint_ref,
        name: undefined,
        position,
        width: undefined,
        decimal_places: undefined,
      }
      return column
    })

  if (!savedColumnOrder) {
    return gridColumnOrder
  }
  return syncColumnOrder(savedColumnOrder, gridColumnOrder)
}

export function syncColumnOrder(
  savedColumnOrder: GridViewColumn[],
  gridColumnOrder: GridViewColumn[]
): GridViewColumn[] {
  const savedColumns = savedColumnOrder.map((column) => column.datapoint_ref)
  const gridColumns = gridColumnOrder.map((column) => column.datapoint_ref)

  const missingFromSaved = gridColumnOrder
    .filter((column) => !savedColumns.includes(column.datapoint_ref))
    .map((column) => {
      // hide columns not saved on views
      return { ...column, position: 'h' as const }
    })

  // remove deleted columns that are still on views
  const savedAndOnGrid = savedColumnOrder
    .filter((column) => gridColumns.includes(column.datapoint_ref))
    // this makes the save button on the views menu turn disabled after saving a view
    // but only when modifying the header width or name
    .map((column) => ({ ...column }))

  return [...savedAndOnGrid, ...missingFromSaved]
}

function moveColumn(columnOrder: GridViewColumn[], fromDatapointRef: string, toDatapointRef: string): GridViewColumn[] {
  const newOrder = [...columnOrder]

  const fromIndex = newOrder.findIndex((column) => column.datapoint_ref === fromDatapointRef)
  const toIndex = newOrder.findIndex((column) => column.datapoint_ref === toDatapointRef)
  const fromPosition = newOrder[fromIndex]!.position
  const toPosition = newOrder[toIndex]!.position

  if (fromPosition !== toPosition) {
    newOrder[fromIndex]!.position = toPosition
  }

  if (fromIndex < toIndex) {
    for (let i = fromIndex; i < toIndex; i++) {
      const temp = newOrder[i]!
      newOrder[i] = newOrder[i + 1]!
      newOrder[i + 1] = temp
    }
  } else {
    for (let i = fromIndex; i > toIndex; i--) {
      const temp = newOrder[i]!
      newOrder[i] = newOrder[i - 1]!
      newOrder[i - 1] = temp
    }
  }

  return newOrder
}

// Based on the last columns, are there any column that changed from hidden to visible.
export function haveColumnsChangedToVisible(
  lastColumns: GridViewColumn[] | null,
  currentColumns: GridViewColumn[] | null
): boolean {
  if (!lastColumns || !currentColumns) {
    return false
  }

  for (const lastColumn of lastColumns) {
    const currentColumn = currentColumns.find((c) => c.datapoint_ref === lastColumn.datapoint_ref)
    if (!currentColumn) {
      continue
    }

    if (lastColumn.position === 'h' && currentColumn.position !== 'h') {
      return true
    }
  }

  return false
}
