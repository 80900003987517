import DateTimeFilterMenu from '../../../components/filters/date-time-filter-menu'
import { NumberFilterMenu } from '../../../components/filters/number-filter-menu'
import { StringFilterMenu } from '../../../components/filters/string-filter-menu'
import ValuesFilterMenu from '../../../components/filters/values-filter-menu'
import { FilterValue } from '../../../services/data/filter-parsing'
import { GridDataViewFilterOperator } from '../../../services/data/types/grid-data-view'
import { ReconGridHeading, ReconGridHeadingKey } from '../../../services/data/types/reconciliation'
import { ReconFilterStateValue } from '../data/reconciliation-filters'

type ReconFilterMenuOptionsProps = {
  anchorEl: HTMLElement | null
  heading: ReconGridHeading
  filter: ReconFilterStateValue | undefined
  onChange: (key: ReconGridHeadingKey, filter: ReconFilterStateValue) => void
  onDelete: (key: ReconGridHeadingKey) => void
  onClose: () => void
}

export function ReconFilterMenuOptions(props: ReconFilterMenuOptionsProps) {
  const { anchorEl, heading, filter, onChange, onDelete, onClose } = props

  const hasFilters = !!filter

  function handleDelete() {
    onDelete(heading.key)
    onClose()
  }

  if (!heading.filterType) {
    return null
  }

  return (
    <>
      {heading.filterType.type === 'select' && (
        <ValuesFilterMenu
          anchorEl={anchorEl}
          options={heading.filterType.options}
          selectedValues={filter?.values.map((value) => ({ value }))}
          isSelectedFilter={hasFilters}
          onChange={(values) => {
            onChange(heading.key, {
              operator: 'eq',
              values: values.map((v) => String(v)),
            })
          }}
          onDelete={handleDelete}
          onClose={onClose}
        />
      )}

      {heading.filterType.type === 'date' && (
        <DateTimeFilterMenu
          anchorEl={anchorEl}
          selectedOperator={filter?.operator}
          selectedValues={filter?.values}
          isSelectedFilter={hasFilters}
          onChange={(operator: GridDataViewFilterOperator, values: FilterValue[]) => {
            onChange(heading.key, {
              operator,
              values: values.map((v) => String(v)),
            })
          }}
          onDelete={handleDelete}
          onClose={onClose}
        />
      )}

      {(heading.filterType.type === 'number' || heading.filterType.type === 'percent') && (
        <NumberFilterMenu
          anchorEl={anchorEl}
          filter={{
            datapointRef: heading.key,
            datapointType: heading.filterType.type === 'percent' ? 'Percent' : 'Float',
          }}
          selectedFilter={filter}
          isSelectedFilter={hasFilters}
          onChange={(filters) => {
            const newFilter = filters[heading.key]
            if (newFilter) {
              onChange(heading.key, {
                operator: newFilter.operator,
                values: newFilter.values.map((v) => Number(v)),
              })
            }
          }}
          onDelete={handleDelete}
          onClose={onClose}
        />
      )}

      {heading.filterType.type === 'string' && (
        <StringFilterMenu
          datapointRef={heading.key}
          selectedFilter={filter}
          anchorEl={anchorEl}
          isSelectedFilter={hasFilters}
          onChange={(filters) => {
            const newFilter = filters[heading.key]
            if (newFilter) {
              onChange(heading.key, {
                operator: newFilter.operator,
                values: newFilter.values.map((v) => String(v)),
              })
            }
          }}
          onDelete={handleDelete}
          onClose={onClose}
        />
      )}
    </>
  )
}
