import * as Sentry from '@sentry/react'
import { UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { i18n } from '../../i18n'

function parseError(error: AxiosError<ServerError | any> | null): string | null {
  const status = error?.response?.status

  if (!error || !status) {
    return null
  }

  if (status >= 500) {
    return i18n.t('common:general_error_message')
  }

  if (!error.response?.data?.error?.msg) {
    return error.response?.statusText || i18n.t('common:general_error_message')
  }

  const message = error.response.data.error.msg
  const code = error.response.data.error.code

  return `${message} (${code})`
}

export function parseErrorResult(result: UseQueryResult<unknown, AxiosError<ServerError | any>>): string | null {
  const errorMsg = parseError(result.error)

  if (!errorMsg && result.failureReason) {
    Sentry.captureMessage(`Query failed with result.failureReason: ${result.failureReason.message}`)
    return i18n.t('common:no_internet_message')
  }

  return errorMsg
}

export type ServerError = {
  error: {
    code: string
    msg: string
  }
}

export default parseError
