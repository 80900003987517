import { DeleteFilterPayload } from '../../modules/portfolios/data/use-view-config-state'
import { FilterValue, GridDataFilterOption, ParsedFilter } from '../../services/data/filter-parsing'
import { GridDataViewFilterOperator, GridDataViewFilters } from '../../services/data/types/grid-data-view'
import DateTimeFilterMenu from './date-time-filter-menu'
import { NumberFilterMenu } from './number-filter-menu'
import { StringFilterMenu } from './string-filter-menu'
import ValuesFilterMenu from './values-filter-menu'

type FilterMenuOptionsProps = {
  anchorEl: HTMLElement | null
  filterOptions: GridDataFilterOption[]
  selectedFilters: ParsedFilter[]
  activeMenuDatapointRef: string
  disableBackdrop: boolean
  onChange: (filters: GridDataViewFilters) => void
  onDelete: (payload: DeleteFilterPayload) => void
  onClose: () => void
}

function FilterMenuOptions(props: FilterMenuOptionsProps) {
  const {
    anchorEl,
    filterOptions,
    selectedFilters,
    activeMenuDatapointRef,
    disableBackdrop,
    onChange,
    onDelete,
    onClose,
  } = props

  const activeMenuOption = filterOptions.find((option) => {
    return option.datapointRef === activeMenuDatapointRef
  })

  const activeMenuSelectedFilter = selectedFilters.find((selectedFilter) => {
    return selectedFilter.datapointRef === activeMenuOption?.datapointRef
  })

  const isSelectedFilter = selectedFilters.includes(activeMenuSelectedFilter!)

  if (!activeMenuOption) {
    return null
  }

  return (
    <>
      {(activeMenuOption.datapointType === 'Classification' ||
        activeMenuOption.datapointType === 'MultiSelect' ||
        activeMenuOption.datapointType === 'AssetRef') &&
        !!activeMenuOption.values.length && (
          <ValuesFilterMenu
            disableBackdrop={disableBackdrop}
            anchorEl={anchorEl}
            options={activeMenuOption.values}
            selectedValues={activeMenuSelectedFilter?.filterValues}
            isSelectedFilter={!!isSelectedFilter}
            onChange={(values) => {
              onChange({
                [activeMenuOption.datapointRef]: {
                  operator: 'eq',
                  values,
                },
              })
            }}
            onDelete={() => {
              onDelete({
                datapointRef: activeMenuOption.datapointRef,
                singleValue: null,
              })
              onClose()
            }}
            onClose={onClose}
            sx={{ ml: -1.5 }}
          />
        )}

      {activeMenuOption.datapointType === 'Boolean' && (
        <ValuesFilterMenu
          disableBackdrop={disableBackdrop}
          anchorEl={anchorEl}
          options={activeMenuOption.values}
          selectedValues={activeMenuSelectedFilter?.filterValues}
          isSelectedFilter={!!isSelectedFilter}
          onChange={(values) => {
            onChange({
              [activeMenuOption.datapointRef]: {
                operator: 'eq',
                values: values.map((value) => value === 'true'),
              },
            })
          }}
          onDelete={() => {
            onDelete({
              datapointRef: activeMenuOption.datapointRef,
              singleValue: null,
            })
            onClose()
          }}
          onClose={onClose}
          sx={{ ml: -1.5 }}
        />
      )}

      {(activeMenuOption.datapointType === 'Classification' || activeMenuOption.datapointType === 'MultiSelect') &&
        !activeMenuOption.values.length && (
          <StringFilterMenu
            disableBackdrop={disableBackdrop}
            datapointRef={activeMenuDatapointRef}
            selectedFilter={activeMenuSelectedFilter}
            anchorEl={anchorEl}
            isSelectedFilter={!!isSelectedFilter}
            onClose={onClose}
            onChange={onChange}
            onDelete={() => {
              onDelete({
                datapointRef: activeMenuOption.datapointRef,
                singleValue: null,
              })
              onClose()
            }}
          />
        )}

      {(activeMenuOption.datapointType === 'DateTime' || activeMenuOption.datapointType === 'NaiveDate') && (
        <DateTimeFilterMenu
          disableBackdrop={disableBackdrop}
          anchorEl={anchorEl}
          selectedOperator={activeMenuSelectedFilter?.operator}
          selectedValues={activeMenuSelectedFilter?.values}
          isSelectedFilter={!!isSelectedFilter}
          onChange={(operator: GridDataViewFilterOperator, values: FilterValue[]) => {
            onChange({
              [activeMenuOption.datapointRef]: {
                operator,
                values,
              },
            })
          }}
          onClose={onClose}
          onDelete={() => {
            onDelete({
              datapointRef: activeMenuOption.datapointRef,
              singleValue: null,
            })
            onClose()
          }}
          sx={{ ml: -1.5 }}
        />
      )}

      {(activeMenuOption.datapointType === 'Float' ||
        activeMenuOption.datapointType === 'Int' ||
        activeMenuOption.datapointType === 'Percent') && (
        <NumberFilterMenu
          disableBackdrop={disableBackdrop}
          anchorEl={anchorEl}
          filter={activeMenuOption}
          selectedFilter={activeMenuSelectedFilter}
          isSelectedFilter={!!isSelectedFilter}
          onChange={onChange}
          onClose={onClose}
          onDelete={() => {
            onDelete({
              datapointRef: activeMenuOption.datapointRef,
              singleValue: null,
            })
            onClose()
          }}
        />
      )}

      {activeMenuOption.datapointType === 'String' && (
        <StringFilterMenu
          disableBackdrop={disableBackdrop}
          datapointRef={activeMenuDatapointRef}
          selectedFilter={activeMenuSelectedFilter}
          anchorEl={anchorEl}
          isSelectedFilter={!!isSelectedFilter}
          onClose={onClose}
          onChange={onChange}
          onDelete={() => {
            onDelete({
              datapointRef: activeMenuOption.datapointRef,
              singleValue: null,
            })
            onClose()
          }}
        />
      )}
    </>
  )
}

export default FilterMenuOptions
