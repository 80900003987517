import { ChevronRight, FilterAlt } from '@mui/icons-material'
import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

type ReconColumnHeaderMenuProps = {
  anchorEl: HTMLElement | null
  isFilterMenuOpen: boolean
  onFilterMenuClose: () => void
  onFilterMenuToggle: (event: MouseEvent<HTMLElement>) => void
}

export function ReconColumnHeaderMenu(props: ReconColumnHeaderMenuProps) {
  const { anchorEl, isFilterMenuOpen, onFilterMenuClose, onFilterMenuToggle } = props

  const { t } = useTranslation('dataTable')

  return (
    <Menu
      className="context-menu"
      transitionDuration={0}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!anchorEl}
      onClose={onFilterMenuClose}
      onContextMenu={(event) => {
        event.preventDefault()
        onFilterMenuClose()
      }}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      <MenuItem
        selected={isFilterMenuOpen}
        onClick={onFilterMenuToggle}
        sx={{
          '&:hover .MuiSvgIcon-root': {
            opacity: 1,
          },
          '&.Mui-selected': {
            background: 'rgba(120, 220, 232, 0.08)',
            '& .MuiSvgIcon-root': {
              opacity: 1,
            },
          },
        }}
      >
        <ListItemIcon>
          <FilterAlt fontSize="small" sx={{ color: 'gray.300' }} />
        </ListItemIcon>
        <ListItemText>
          <Typography fontSize="14px">{t('filter_by')}</Typography>
        </ListItemText>
        <ChevronRight color="primary" sx={{ fontSize: 18, opacity: 0 }} />
      </MenuItem>
    </Menu>
  )
}
