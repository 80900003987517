import { Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { IRowNode } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { MouseEvent, RefObject, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CollapsibleSideNavigation from '../../../components/collapsible-side-navigation'
import ConfirmationModal from '../../../components/confirmation-modal'
import ErrorSnackbar from '../../../components/error-snackbar'
import AppLayout from '../../../components/layouts/app-layout'
import ListItem from '../../../components/list-item'
import ListItemGroup from '../../../components/list-item-group'
import { queryClient } from '../../../services/data/react-query'
import { ReconMatch, ReconRecord, ReconTxnLocal } from '../../../services/data/types/reconciliation'
import useOpenState from '../../../utils/hooks/use-open-state'
import useUserInfo from '../../auth/data/use-user-info'
import usePortfoliosQuery from '../../portfolios/data/use-portfolios-query'
import { SelectedTxns, processGrids } from '../data/reconciliation-data'
import { useReconFilters } from '../data/reconciliation-filters'
import {
  useReconcileTransactionsMutation,
  useReconciliationGridQuery,
  useUnreconcileRecordMutation,
} from '../data/reconciliation-hooks'
import useBankAccountsNavigatorQuery from '../data/use-bank-accounts-navigator-query'
import useCancelTransactionMutation from '../data/use-cancel-transaction-mutation'
import BankAccountsGrid, { AgRowData } from './bank-accounts-grid'
import BankAccountsHeader from './bank-accounts-header'
import BankRecordsUploadModal from './bank-records-upload-modal'
import ContextMenu, { ContextMenuState } from './context-menu'
import EnterBankTransactionModal from './enter-bank-transaction-modal'
import EnterCounterpatySettlementModal from './enter-counterpary-settlement-modal'
import MatchModal from './match-modal'
import PaymentForRecordModal from './payment-for-record-modal'

type BankAccountsRootProps = {
  onBankAccountSelect: (bankAccountId: number, holderLei: string) => void
}

type Layout = 'bank' | 'ledger' | 'reconcile'

export function BankAccountsRoot(props: BankAccountsRootProps) {
  const { onBankAccountSelect } = props

  const { t } = useTranslation('bankAccounts')

  const { allowManualPayments } = useUserInfo()
  const { bankAccountId, holderLei } = useParams()

  const [bankGridRef, setBankGridRef] = useState<RefObject<AgGridReact> | null>(null)
  const [ledgerGridRef, setLedgerGridRef] = useState<RefObject<AgGridReact> | null>(null)

  const nav = useOpenState({ open: true })
  const [layout, setLayout] = useState<Layout>('bank')
  const reconciling = layout === 'reconcile'
  const showBankGrid = reconciling || layout === 'bank'
  const showLedgerGrid = reconciling || layout === 'ledger'

  const cancelTransaction = useCancelTransactionMutation()
  const reconcileTransactions = useReconcileTransactionsMutation()
  const unreconcileRecord = useUnreconcileRecordMutation()

  const navigatorQuery = useBankAccountsNavigatorQuery()
  const navigator = navigatorQuery.data?.data || null
  const entities = navigator?.related_entities || []
  const allAccounts = navigator?.accounts || []
  const remainingAccounts = allAccounts.filter((account) => !account.related_entity_id)

  const portfoliosQuery = usePortfoliosQuery()
  let portfolios = portfoliosQuery.data?.data || []
  portfolios = portfolios.filter((p) => p.lei === holderLei)

  const gridQuery = useReconciliationGridQuery(bankAccountId)
  const grid = gridQuery.data?.data || null

  const uploadModal = useOpenState()
  const [contextMenu, setContextMenu] = useState<ContextMenuState | null>(null)

  const matchModal = useOpenState()
  const confirmReconcileModal = useOpenState()
  const confirmUnreconcileModal = useOpenState()
  const [selectedRecord, setSelectedRecord] = useState<ReconRecord | null>(null)
  const [selectedTxns, setSelectedTxns] = useState<SelectedTxns>({})
  const [matches, setMatches] = useState<Array<ReconMatch>>([])

  const counterpartySettlementModal = useOpenState()
  const bankTransactionModal = useOpenState()
  const [newBankPaymentForRecord, setNewBankPaymentForRecord] = useState<ReconRecord | null>(null)
  const [editBankTxnRef, setEditBankTxnRef] = useState<string | null>(null)
  const [editSettlementRef, setEditSettlementTxnRef] = useState<string | null>(null)

  const loneBankFilters = useReconFilters()
  const loneLedgerFilters = useReconFilters()
  const reconBankFilters = useReconFilters()
  const reconLedgerFilters = useReconFilters()
  const bankFilters = reconciling ? reconBankFilters : loneBankFilters
  const ledgerFilters = reconciling ? reconLedgerFilters : loneLedgerFilters

  const [bankGrid, ledgerGrid] = useMemo(
    () =>
      processGrids(
        grid,
        selectedRecord,
        selectedTxns,
        matches,
        bankFilters.filters,
        ledgerFilters.filters,
        reconciling
      ),
    [grid, selectedRecord, selectedTxns, matches, bankFilters.filters, ledgerFilters.filters, reconciling]
  )

  const canMatch = !!selectedRecord && !!Object.keys(selectedTxns).length
  const canReconcile = !!matches.length

  const isLoading = gridQuery.isFetching || cancelTransaction.isLoading

  useEffect(() => {
    if (matches.length === 0) {
      setMatches(grid?.matches || [])
    }
  }, [grid])

  useEffect(() => {
    if (!bankAccountId && navigatorQuery.isSuccess) {
      const firstEntityAccount = entities.find((entity) => !!entity.accounts[0])?.accounts[0]
      const account = firstEntityAccount || remainingAccounts[0]
      if (account) {
        onBankAccountSelect(account.account_id, account.holder_lei)
      }
    }
  }, [bankAccountId, navigatorQuery])

  function reloadData() {
    setSelectedRecord(null)
    setSelectedTxns({})

    // reload other tabs queries, even ones with other back accounts
    // as the ledger is shared between accounts with the same currency
    queryClient.invalidateQueries(['reconciliation'])
    queryClient.invalidateQueries(['bank-accounts', 'navigator'])
  }

  function handleSelectGridRow(record: ReconRecord | null, txn: ReconTxnLocal | null, checked: boolean) {
    if (record) {
      if (checked) {
        setSelectedRecord(record)
      } else {
        setSelectedRecord(null)
      }
    } else if (txn) {
      if (checked) {
        setSelectedTxns((prev) => {
          return { ...prev, [txn.ext_segment_ref]: txn }
        })
      } else {
        setSelectedTxns((prev) => {
          const update = { ...prev }
          delete update[txn.ext_segment_ref]
          return update
        })
      }
    }
  }

  function handleContextMenuOpen(event: MouseEvent, cell: AgRowData) {
    event.preventDefault()

    const recordId = cell.record?.record_id || null
    const extSegmentRef = cell.txn?.ext_segment_ref || null
    const record = grid?.records.find((r) => r.record_id === recordId)

    const canUnmatchRecord = reconciling && !!recordId && matches.some((m) => m.record_id === recordId)
    const canUnmatchTxn =
      reconciling && !!extSegmentRef && matches.some((m) => m.txns.some((t) => t.ext_segment_ref === extSegmentRef))
    const canUnmatch = canUnmatchRecord || canUnmatchTxn
    const canUnreconcile = reconciling && !!record?.is_reconciled
    const canCreateBankPayment = reconciling && !canUnmatch && !!record && !record.is_reconciled

    // const canEdit = !recordId && !!cell.txn && !cell.txn.is_reconciled
    const canEdit = false
    const canCancel = canEdit

    const editTxn =
      cell.txn && canEdit
        ? {
            txnRef: cell.txn.txn_ref,
            isBankTxn: cell.txn.is_bank_txn,
          }
        : null

    setContextMenu({
      coordinates: {
        mouseX: event.clientX,
        mouseY: event.clientY,
      },
      isBankGrid: cell.isBankGrid,
      editTxn,
      recordId,
      extSegmentRef,
      canEdit,
      canCancel,
      canUnmatch,
      canUnreconcile,
      canCreateBankPayment,
    })
  }

  function handleCancelTransaction() {
    if (bankAccountId && contextMenu?.editTxn) {
      const txnRef = contextMenu.editTxn.txnRef
      setContextMenu(null)

      cancelTransaction.mutate(
        { bankAccountId, txnRef },
        {
          onSuccess: () => gridQuery.refetch(),
        }
      )
    }
  }

  function handleEditTransaction() {
    if (contextMenu?.editTxn) {
      if (contextMenu.editTxn.isBankTxn) {
        setEditBankTxnRef(contextMenu.editTxn.txnRef)
        bankTransactionModal.open()
      } else {
        setEditSettlementTxnRef(contextMenu.editTxn.txnRef)
        counterpartySettlementModal.open()
      }
      setContextMenu(null)
    }
  }

  function handleUnmatchRecord() {
    const recordId = contextMenu?.recordId || null
    setContextMenu(null)

    if (!recordId) {
      return
    }

    setMatches((prevMatches) => {
      return prevMatches
        .map((prevMatch) => {
          if (prevMatch.record_id == recordId) {
            const newMatch = window.structuredClone(prevMatch)
            newMatch.txns = []
            return newMatch
          }
          return prevMatch
        })
        .filter((match) => {
          return match.txns.length > 0
        })
    })
  }

  function handleUnreconcileTxn() {
    const recordId = contextMenu?.recordId || null
    setContextMenu(null)

    if (!bankAccountId || !recordId) {
      return
    }

    unreconcileRecord.mutate(
      { bankAccountId, recordId },
      {
        onSuccess: () => {
          reloadData()
          confirmUnreconcileModal.close()
        },
      }
    )
  }

  function handleCreateBankTxnForRecord() {
    if (!contextMenu || !grid) {
      return
    }

    const recordId = contextMenu.recordId
    if (!recordId) {
      return
    }

    const record = grid.records.find((r) => r.record_id === recordId)
    if (!record) {
      return
    }

    if (record.amount_reconciled !== 0) {
      console.error('Impossible: record is partially reconciled')
      return
    }

    setNewBankPaymentForRecord(record)
    setContextMenu(null)
  }

  function handleCreatePaymentForRecord(match: ReconMatch) {
    if (!bankAccountId) {
      return
    }

    handleSaveMatch(match)
    handleClosePaymentForRecordModal()
  }

  function handleClosePaymentForRecordModal() {
    setNewBankPaymentForRecord(null)
    reconcileTransactions.reset()
  }

  function handleSaveMatch(newMatch: ReconMatch): void {
    matchModal.close()
    setSelectedRecord(null)
    setSelectedTxns({})

    setMatches((prevMatches) => {
      const matchesUpdate = window.structuredClone(prevMatches)
      const recordMatch = matchesUpdate.find((p) => p.record_id === newMatch.record_id)

      if (recordMatch) {
        // record match already exists
        for (const newTxnMatch of newMatch.txns) {
          const existingRecordTxn = recordMatch.txns.find((t) => t.ext_segment_ref === newTxnMatch.ext_segment_ref)

          if (existingRecordTxn) {
            // merge txn amount
            existingRecordTxn.amount += newTxnMatch.amount
          } else {
            // add new txn match
            recordMatch.txns.push(newTxnMatch)
          }
        }
      } else {
        // new record match
        matchesUpdate.push(newMatch)
      }

      return matchesUpdate
    })
  }

  function reconcileMatchedTxns() {
    if (!bankAccountId) {
      return
    }

    reconcileTransactions.mutate(
      {
        bankAccountId,
        payload: {
          matches: matches,
        },
      },
      {
        onSuccess: () => {
          confirmReconcileModal.close()
          setMatches([])
          reloadData()
        },
      }
    )
  }

  function selectRelatedRowsOnLedgerGrid(rowClicked: AgRowData) {
    if (!ledgerGridRef?.current || !reconciling) {
      return
    }

    const isRogueTxn = !rowClicked.record && !rowClicked.txn

    ledgerGridRef.current.api.deselectAll()

    const rows: IRowNode[] = []
    ledgerGridRef.current.api.forEachNode((row) => {
      const rowData: AgRowData = row.data
      if (
        rowData.txn?.ext_segment_ref === rowClicked.txn?.ext_segment_ref ||
        (isRogueTxn && rowData.txn?.segment_ref === (rowClicked.cells['txn_segment_ref']?.value as any)?.['String'])
      ) {
        rows.push(row)
      }
    })

    ledgerGridRef.current.api.setNodesSelected({ nodes: rows, newValue: true })
    if (rows[0]) {
      ledgerGridRef.current.api.ensureNodeVisible(rows[0])
    }
  }

  function selectRelatedRowsOnBankGrid(rowClicked: AgRowData): void {
    if (!bankGridRef?.current || !reconciling) {
      return
    }

    bankGridRef.current.api.deselectAll()

    const rows: IRowNode[] = []
    bankGridRef.current.api.forEachNode((row) => {
      const rowData: AgRowData = row.data
      const isRogueTxn = !rowData.record && !rowData.txn

      if (
        rowData.txn?.ext_segment_ref === rowClicked.txn?.ext_segment_ref ||
        (isRogueTxn && rowClicked.txn?.segment_ref === (rowData.cells['txn_segment_ref']?.value as any)?.['String'])
      ) {
        rows.push(row)
      }
    })

    bankGridRef.current.api.setNodesSelected({ nodes: rows, newValue: true })
    if (rows[0]) {
      bankGridRef.current.api.ensureNodeVisible(rows[0])
    }
  }

  function handleCopy() {
    if (contextMenu?.isBankGrid) {
      bankGridRef?.current?.api.copySelectedRangeToClipboard()
    } else {
      ledgerGridRef?.current?.api.copySelectedRangeToClipboard()
    }
    setContextMenu(null)
  }

  if (!bankAccountId) {
    if (navigatorQuery.isSuccess && allAccounts.length === 0) {
      return (
        <AppLayout direction="row">
          <Box sx={{ p: 2 }}>{t('no_bank_accounts')}</Box>
        </AppLayout>
      )
    }
    return null
  }

  return (
    <>
      <AppLayout direction="row">
        <CollapsibleSideNavigation title={t('navigation.title')} open={nav.isOpen} onClose={nav.close}>
          {entities.map((entity) => (
            <ListItemGroup key={entity.entity_id} isDefaultOpen title={entity.entity_name}>
              {!entity.accounts.length && <ListItem title={t('navigation.no_accounts')} disabled />}
              {entity.accounts.map((account) => (
                <ListItem
                  key={account.account_id}
                  title={account.account_name}
                  selected={String(account.account_id) === bankAccountId}
                  onClick={() => {
                    setMatches([])
                    setSelectedRecord(null)
                    setSelectedTxns({})
                    onBankAccountSelect(account.account_id, account.holder_lei)
                  }}
                />
              ))}
            </ListItemGroup>
          ))}

          {!!remainingAccounts.length && (
            <ListItemGroup isDefaultOpen title={t('navigation.other_accounts')}>
              {remainingAccounts.map((account) => (
                <ListItem
                  key={account.account_id}
                  title={account.account_name}
                  selected={String(account.account_id) === bankAccountId}
                  onClick={() => {
                    setMatches([])
                    setSelectedRecord(null)
                    setSelectedTxns({})
                    onBankAccountSelect(account.account_id, account.holder_lei)
                  }}
                />
              ))}
            </ListItemGroup>
          )}
        </CollapsibleSideNavigation>

        <Stack flex={1} overflow="hidden">
          <BankAccountsHeader
            title={bankGrid?.account_name || ''}
            currency={bankGrid?.currency || ''}
            showLoading={isLoading}
            isNavOpen={nav.isOpen}
            allowManualPayments={allowManualPayments}
            onOpenNavClick={nav.open}
            onNewCounterpartyTransfer={counterpartySettlementModal.open}
            onNewBankTransfer={bankTransactionModal.open}
            onUploadClick={uploadModal.open}
            onReloadData={reloadData}
          />

          <Stack sx={{ flexDirection: 'row', height: '100%', p: 2, gap: 1 }}>
            <Stack sx={{ flex: 1 }}>
              <Stack direction="row" sx={{ height: 35, gap: 2, mb: 2 }}>
                <ToggleButtonGroup
                  size="small"
                  value={layout}
                  exclusive
                  onChange={(_, newLayout) => {
                    if (newLayout) {
                      setLayout(newLayout)
                    }
                  }}
                >
                  <ToggleButton value="bank">{t('header.bank_records')}</ToggleButton>
                  <ToggleButton value="ledger">{t('header.ledger_txns')}</ToggleButton>
                  <ToggleButton value="reconcile">{t('header.reconcile')}</ToggleButton>
                </ToggleButtonGroup>

                {reconciling && (
                  <>
                    <Button
                      size="small"
                      color="info"
                      variant={canMatch ? 'contained' : 'outlined'}
                      disableElevation
                      disabled={!canMatch}
                      onClick={matchModal.open}
                    >
                      {t('header.match')}
                    </Button>
                    <Button
                      size="small"
                      variant={canReconcile ? 'contained' : 'outlined'}
                      disableElevation
                      disabled={!canReconcile}
                      onClick={confirmReconcileModal.open}
                    >
                      {t('header.reconcile') + (matches.length ? ` (${matches.length})` : '')}
                    </Button>
                  </>
                )}
              </Stack>

              <BankAccountsGrid
                hide={!showBankGrid}
                grid={bankGrid}
                filterState={bankFilters.filters}
                onRowClick={selectRelatedRowsOnLedgerGrid}
                onContextMenuOpen={handleContextMenuOpen}
                onForwardGridRef={setBankGridRef}
                onSelectRow={handleSelectGridRow}
                onAddFilter={bankFilters.setFilter}
                onDeleteFilter={bankFilters.removeFilter}
              />
              {reconciling && <Box sx={{ mb: 2 }} />}
              <BankAccountsGrid
                hide={!showLedgerGrid}
                grid={ledgerGrid}
                filterState={ledgerFilters.filters}
                onRowClick={selectRelatedRowsOnBankGrid}
                onContextMenuOpen={handleContextMenuOpen}
                onForwardGridRef={setLedgerGridRef}
                onSelectRow={handleSelectGridRow}
                onAddFilter={ledgerFilters.setFilter}
                onDeleteFilter={ledgerFilters.removeFilter}
              />
            </Stack>
          </Stack>
        </Stack>
      </AppLayout>

      <BankRecordsUploadModal
        open={uploadModal.isOpen}
        onSucess={() => {
          uploadModal.close()
          reloadData()
        }}
        onClose={uploadModal.close}
      />

      <EnterCounterpatySettlementModal
        open={counterpartySettlementModal.isOpen}
        bankAccountId={bankAccountId}
        txnRef={editSettlementRef}
        onSaved={reloadData}
        onClose={() => {
          setEditSettlementTxnRef(null)
          counterpartySettlementModal.close()
        }}
      />
      <EnterBankTransactionModal
        open={bankTransactionModal.isOpen}
        bankAccountId={bankAccountId}
        txnRef={editBankTxnRef}
        portfolios={portfolios}
        accountPortfolioRef={grid?.portfolio_ref}
        onSaved={reloadData}
        onClose={() => {
          setEditBankTxnRef(null)
          bankTransactionModal.close()
        }}
      />

      <MatchModal
        open={!!matchModal.isOpen}
        record={selectedRecord}
        transactions={selectedTxns}
        matches={matches}
        portfolios={portfolios}
        accountPortfolioRef={grid?.portfolio_ref}
        onSave={handleSaveMatch}
        onClose={matchModal.close}
      />
      <PaymentForRecordModal
        key={newBankPaymentForRecord?.record_id}
        open={!!newBankPaymentForRecord}
        record={newBankPaymentForRecord}
        portfolios={portfolios}
        accountPortfolioRef={grid?.portfolio_ref}
        error={reconcileTransactions.error}
        onCreate={handleCreatePaymentForRecord}
        onClose={handleClosePaymentForRecordModal}
      />

      <ConfirmationModal
        title={t('confirm_reconciliation_modal.title')}
        confirmButtonText={t('confirm_reconciliation_modal.button_label')}
        open={confirmReconcileModal.isOpen}
        error={reconcileTransactions.error}
        isLoading={reconcileTransactions.isLoading}
        onCloseButtonClick={confirmReconcileModal.close}
        onConfirmButtonClick={reconcileMatchedTxns}
      >
        <Typography variant="body1">{t('confirm_reconciliation_modal.message')}</Typography>
      </ConfirmationModal>
      <ConfirmationModal
        title={t('confirm_unreconciliation_modal.title')}
        confirmButtonText={t('confirm_unreconciliation_modal.button_label')}
        open={confirmUnreconcileModal.isOpen}
        error={unreconcileRecord.error}
        isLoading={unreconcileRecord.isLoading}
        onCloseButtonClick={confirmUnreconcileModal.close}
        onConfirmButtonClick={handleUnreconcileTxn}
      >
        <Typography variant="body1">{t('confirm_unreconciliation_modal.message')}</Typography>
      </ConfirmationModal>

      <ContextMenu
        state={contextMenu}
        onEdit={handleEditTransaction}
        onCancel={handleCancelTransaction}
        onUnmatch={handleUnmatchRecord}
        onUnreconcile={confirmUnreconcileModal.open}
        onCreateBankTxn={handleCreateBankTxnForRecord}
        onCopy={handleCopy}
        onClose={() => setContextMenu(null)}
      />

      <ErrorSnackbar open={gridQuery.isError} message={gridQuery.error} />
      <ErrorSnackbar
        open={cancelTransaction.isError}
        message={cancelTransaction.error}
        onClose={cancelTransaction.reset}
      />
    </>
  )
}
