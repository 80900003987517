import { Typography } from '@mui/material'

type FilterMenuLabelProps = {
  children: string
}

function FilterMenuLabel(props: FilterMenuLabelProps) {
  const { children } = props

  return (
    <Typography variant="overline" color="gray.700" sx={{ py: 0.5, px: 3 }}>
      {children}
    </Typography>
  )
}

export default FilterMenuLabel
