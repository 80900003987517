import { ExtractUnion } from '../../../utils/types'
import { FilterValue } from '../filter-parsing'
import { UamPanelMode } from './grid-panels'
import { Group } from './group'
import { ViewType } from './view'

export type GridDataView = {
  view_ref: string
  view_name: string
  can_modify: boolean
  dataset_ref: string
  view_type: ViewType
  group_owner: Group
  view_options: GridView
}

export type GridDataViewSortBy = {
  column: string
  direction: 'asc' | 'dsc'
}

export type GridDataViewFilters = {
  [datapointRef: string]: {
    operator: GridDataViewFilterOperator
    values: FilterValue[]
  }
}

export const filterOperators = [
  'eq',
  'lt',
  'lte',
  'gt',
  'gte',
  'btwn',
  'contains',
  'today',
  'yesterday',
  'thismonth',
  'lastmonth',
  'lastxdays',
  'nextxdaysinc',
  'nextxdaysexc',
] as const

export type GridDataViewFilterOperator = (typeof filterOperators)[number]

export type StringOperators = ExtractUnion<GridDataViewFilterOperator, 'contains'>

export type NumberOperators = ExtractUnion<GridDataViewFilterOperator, 'eq' | 'lt' | 'lte' | 'gt' | 'gte' | 'btwn'>

export type DateOperators = ExtractUnion<
  GridDataViewFilterOperator,
  | 'eq'
  | 'lt'
  | 'gt'
  | 'btwn'
  | 'today'
  | 'yesterday'
  | 'thismonth'
  | 'lastmonth'
  | 'lastxdays'
  | 'nextxdaysinc'
  | 'nextxdaysexc'
>

export type GridDataViewCreateBody = {
  view_name: string
  dataset_ref: string
  view_type: ViewType
  view_options: GridView
}

export type GridDataViewUpdateBody = {
  view_name?: string
  owner_group_ref?: string
  view_options?: GridView
}

export type GridView = {
  aggregation: string[]
  filters: GridDataViewFilters | null
  columns: GridViewColumn[] | null
  panel_mode: UamPanelMode | null
  sort_by: GridDataViewSortBy | null
  asset_types: string[] | null
  as_of_date: string | null
}

export type GridViewColumn = {
  datapoint_ref: string
  name?: string
  position: GridViewColumnPosition
  width?: number
  decimal_places?: number
  display_raw?: true
}

export type GridViewColumnPosition = 'm' | 'h' | 'p'
