import { ChevronLeft, Edit, KeyboardArrowUp } from '@mui/icons-material'
import {
  Button,
  IconButton,
  SvgIconTypeMap,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { Box, Stack } from '@mui/system'
import { useTranslation } from 'react-i18next'
import DatapointCell from '../../../components/data-table/datapoint-cell'
import { OrderTable, OrderTableRow } from '../../../services/data/types/order'

type OrderPanelProps<Row extends OrderTableRow, Table extends OrderTable<Row>> = {
  isMinimized: boolean
  isOpen: boolean
  showOverlay: boolean
  title: string
  table: Table
  actionButtonText?: string
  editButtonText?: string
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  onToggle: () => void
  onEditRowClick?: (row: Table['rows'][number]) => void
  onActionButtonClick?: () => void
}

function OrderPanel<Row extends OrderTableRow, Table extends OrderTable<Row>>(props: OrderPanelProps<Row, Table>) {
  const {
    isMinimized,
    isOpen,
    showOverlay,
    title,
    table,
    actionButtonText,
    editButtonText,
    Icon,
    onToggle,
    onEditRowClick,
    onActionButtonClick,
  } = props

  const { t } = useTranslation('common')

  if (isMinimized) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          flex: 1,
          padding: 1,
          borderRadius: '4px',
          backgroundColor: 'gray.main',
        }}
      >
        <Icon sx={{ mx: 1 }} />
        <Typography>{title}</Typography>
        <IconButton title={t('open')} onClick={onToggle} sx={{ ml: 'auto' }}>
          <KeyboardArrowUp />
        </IconButton>
      </Stack>
    )
  }

  if (!isOpen) {
    return (
      <Stack
        alignItems="center"
        sx={{
          padding: 1,
          borderRadius: '4px',
          backgroundColor: 'gray.main',
        }}
      >
        <IconButton title={title} onClick={onToggle}>
          <Icon />
        </IconButton>
      </Stack>
    )
  }

  return (
    <Box sx={{ flex: '1' }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          height: '56px',
          paddingX: 2,
          backgroundColor: 'gray.main',
          borderTopRightRadius: '4px',
          borderTopLeftRadius: '4px',
        }}
      >
        <Icon sx={{ mr: 1 }} />
        <Typography sx={{ mr: 'auto' }}>{title}</Typography>

        {!!actionButtonText && (
          <Button
            variant="outlined"
            size="small"
            onClick={onActionButtonClick}
            sx={{ mx: 1, whiteSpace: 'nowrap', overflow: 'hidden' }}
          >
            {actionButtonText}
          </Button>
        )}

        <IconButton title={t('close')} onClick={onToggle}>
          <ChevronLeft />
        </IconButton>
      </Stack>

      <TableContainer sx={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}>
        <Table className="grid">
          <TableHead>
            <TableRow>
              {table.headings.map((heading) => (
                <TableCell key={heading}>{heading}</TableCell>
              ))}
              {!!onEditRowClick && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody className={showOverlay ? 'show-overlay' : ''}>
            {table.rows.map((row, index) => (
              <TableRow key={index}>
                {row.columns.map((column, index) => (
                  <TableCell key={index}>
                    <div className="row-content">
                      <DatapointCell datapoint={column} />
                    </div>
                  </TableCell>
                ))}
                {!!onEditRowClick && (
                  <TableCell align="center" padding="checkbox">
                    <IconButton title={editButtonText} onClick={() => onEditRowClick(row)}>
                      <Edit sx={{ height: '16px', width: '16px' }} />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default OrderPanel
