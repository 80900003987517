import { useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import api from '../../../services/data/api'
import { parseErrorResult, ServerError } from '../../../services/data/parse-error'
import { GridResponse } from '../../../services/data/types/grid-data'
import { GridDataViewFilters, GridViewColumn } from '../../../services/data/types/grid-data-view'
import { UamPanelMode } from '../../../services/data/types/grid-panels'

function usePortfolioQuery(
  portfolioRef: string | null | undefined,
  datasetRef: string | null,
  whatifRef: string | null,
  aggregations: string[],
  panelMode: UamPanelMode | null,
  filters: GridDataViewFilters | null,
  panelColumns: string[] | null,
  columns: GridViewColumn[] | null,
  columnsUpdateTicker: number,
  loadAllColumns: boolean,
  asOfDate: string | null,
  options: { enabled: boolean }
) {
  const result = useQuery<AxiosResponse<GridResponse> | null, AxiosError<ServerError>>(
    [
      'portfolio',
      portfolioRef,
      whatifRef,
      aggregations,
      datasetRef,
      panelMode,
      filters,
      panelColumns,
      columnsUpdateTicker,
      loadAllColumns,
      asOfDate,
    ],
    ({ signal }) => {
      if (!portfolioRef) {
        return null
      }

      // visible columns should not trigger a refetch
      const visibleColumns = columns?.filter((column) => column.position === 'm').map((column) => column.datapoint_ref)

      return api
        .getPortfolio(
          portfolioRef,
          datasetRef,
          {
            aggregation: aggregations,
            whatifset: whatifRef,
            panel_mode: panelMode,
            filters,
            panel_columns: panelColumns,
            visible_columns: visibleColumns,
          },
          loadAllColumns,
          asOfDate,
          signal
        )
        .then((response) => {
          return {
            ...response,
            data: response.data,
          }
        })
    },
    {
      enabled: !!portfolioRef && !!datasetRef && options.enabled,
      cacheTime: 0,
      keepPreviousData: true,
      // trigger requests even when there is no internet
      networkMode: 'always',
    }
  )

  return { ...result, error: parseErrorResult(result) }
}

export default usePortfolioQuery
