import { Search, Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ParsedGridColumnOption } from '../../services/data/grid-data-parsing'
import FilterMenuLabel from '../filters/filter-menu-label'

type ColumnsMenuProps = {
  anchorEl: HTMLElement | null
  columns: ParsedGridColumnOption[]
  onHideAll: () => void
  onUnhideAll: () => void
  onToggleHiddenColumn: (datapointRef: string) => void
  onClose: () => void
}

function ColumnsMenu(props: ColumnsMenuProps) {
  const { anchorEl, columns, onHideAll, onUnhideAll, onToggleHiddenColumn, onClose } = props

  const { t } = useTranslation('dataTable')

  const [searchValue, setSearchValue] = useState('')
  const [hiddenStates, setHiddenStates] = useState<Record<string, boolean>>({})

  const searchInputRef = useRef<HTMLInputElement>(null)
  const listRef = useRef<HTMLDivElement>(null)

  const filteredColumns = columns.filter((option) => {
    return (
      option.datapointName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      option.columnName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    )
  })

  const canSave = !!Object.keys(hiddenStates).length

  function handleToggleColumn(column: ParsedGridColumnOption) {
    setHiddenStates((prev) => {
      const next = { ...prev }
      const isHidden = next[column.datapointRef] ?? column.isHidden
      next[column.datapointRef] = !isHidden
      return next
    })
  }

  function handleHideAll() {
    setAllColumnAs(true)
  }

  function handleUnhideAll() {
    setAllColumnAs(false)
  }

  function setAllColumnAs(hidden: boolean) {
    setHiddenStates(
      columns.reduce(
        (acc, column) => {
          acc[column.datapointRef] = hidden
          return acc
        },
        {} as Record<string, boolean>
      )
    )
  }

  function handleSave() {
    for (const [datapointRefKey, toggleValue] of Object.entries(hiddenStates)) {
      const column = columns.find((c) => c.datapointRef === datapointRefKey)
      if (!column) {
        continue
      }
      if (column.isHidden !== toggleValue) {
        onToggleHiddenColumn(datapointRefKey)
      }
    }
    handleClose()
  }

  function handleClose() {
    onClose()
    setSearchValue('')
    setHiddenStates({})
  }

  function handleSearchChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchValue(event.target.value)
  }

  function handleSearchNavigation(event: KeyboardEvent<HTMLDivElement>) {
    event.stopPropagation()
    if (event.key === 'ArrowUp') {
      const allListItems = listRef.current?.querySelectorAll('li')
      const lastListItem = allListItems && allListItems[allListItems.length - 1]

      if (lastListItem) {
        handleFocus(event, lastListItem as HTMLElement)
        return
      }
    }

    if (event.key === 'ArrowDown') {
      const firstListItem = listRef.current?.querySelector('li')

      if (firstListItem) {
        handleFocus(event, firstListItem as HTMLElement)
        return
      }
    }

    if (event.key === 'Escape') {
      handleClose()
    }
  }

  function handleListItemNavigation(event: KeyboardEvent<HTMLElement>) {
    if (event.key === 'ArrowUp') {
      const firstListItem = listRef.current?.querySelector('li')

      if (event.currentTarget === firstListItem) {
        handleFocus(event, searchInputRef.current as HTMLElement)
        return
      }
    }

    if (event.key === 'ArrowDown') {
      const allListItems = listRef.current?.querySelectorAll('li')
      const lastListItem = allListItems && allListItems[allListItems.length - 1]

      if (event.currentTarget === lastListItem) {
        handleFocus(event, searchInputRef.current as HTMLElement)
        return
      }
    }
  }

  function handleFocus(event: KeyboardEvent<HTMLElement>, element: HTMLElement) {
    event.preventDefault()
    event.stopPropagation()
    element.focus()
  }

  return (
    <Menu
      ref={listRef}
      className="context-menu"
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: { mt: 1, width: '350px' },
      }}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
    >
      <Stack direction="row" pr={2}>
        <FilterMenuLabel>{t('hidden_columns')}</FilterMenuLabel>
        <Button variant="text" onClick={handleHideAll} sx={{ fontSize: 13, ml: 'auto' }}>
          {t('hide_all')}
        </Button>
        {false && (
          <Button variant="text" onClick={handleUnhideAll} sx={{ fontSize: 13 }}>
            {t('unhide_all')}
          </Button>
        )}
      </Stack>

      <TextField
        size="small"
        label={t('search_columns')}
        autoFocus
        inputRef={searchInputRef}
        onKeyDown={(event) => handleSearchNavigation(event)}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">{<Search />}</InputAdornment>,
        }}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 1, mx: 2.5, width: '88%' }}
      />

      <Box sx={{ height: '100%', maxHeight: '50vh', overflowY: 'auto' }}>
        {filteredColumns.map((column) => {
          const showTooltip = column.columnName !== column.datapointName
          const isHidden = hiddenStates[column.datapointRef] ?? column.isHidden

          return (
            <Tooltip key={column.datapointRef} title={showTooltip && column.datapointName} arrow disableInteractive>
              <MenuItem
                onClick={() => handleToggleColumn(column)}
                tabIndex={0}
                onKeyDown={(event) => handleListItemNavigation(event)}
                sx={{ my: 0.5, py: 1 }}
              >
                <ListItemText>
                  <Typography variant="body2" whiteSpace="pre-wrap" color={isHidden ? 'gray.200' : 'white'}>
                    {column.columnName}
                  </Typography>
                </ListItemText>
                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                  {isHidden ? (
                    <VisibilityOff sx={{ color: 'gray.200', fontSize: 20 }} />
                  ) : (
                    <Visibility sx={{ color: 'gray.700', fontSize: 20 }} />
                  )}
                </ListItemIcon>
              </MenuItem>
            </Tooltip>
          )
        })}
      </Box>

      <Stack direction="row" justifyContent="flex-end" py={1} px={3} gap={1}>
        <Button variant="text" onClick={handleClose} sx={{ fontSize: 13 }}>
          {t('common:cancel')}
        </Button>
        <Button variant="contained" disabled={!canSave} onClick={handleSave} sx={{ fontSize: 13 }}>
          {t('common:apply')}
        </Button>
      </Stack>
    </Menu>
  )
}

export default ColumnsMenu
