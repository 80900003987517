import { Bookmark, PlaylistAdd, Upload, ViewColumn } from '@mui/icons-material'
import { Badge, IconButton, Tooltip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { AgGridReact } from 'ag-grid-react'
import { MouseEvent, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ReloadButton } from '../../../components/buttons/reload-button'
import ConfirmationModal from '../../../components/confirmation-modal'
import ColumnsMenu from '../../../components/data-table/columns-menu'
import FilterButton from '../../../components/filters/filter-button'
import { StaticResponse } from '../../../services/data/types/asset-static-data'
import { GridDataViewCreateBody, GridDataViewUpdateBody } from '../../../services/data/types/grid-data-view'
import { TradeTicketResponse } from '../../../services/data/types/trade-ticket'
import useOpenState from '../../../utils/hooks/use-open-state'
import useUserInfo from '../../auth/data/use-user-info'
import { ViewEditParams } from '../../portfolios/components/view-edit-modal'
import ViewOptionsMenu from '../../portfolios/components/view-options-menu'
import { ViewSaveAsParams } from '../../portfolios/components/view-save-as-modal'
import useViewConfigState, { initColumnOrder } from '../../portfolios/data/use-view-config-state'
import useTradeTicketQuery from '../../trade-ticket/data/use-trade-ticket-query'
import { ParsedAssetGridCell, ParsedAssetGridData, parseAssetStaticData } from '../data/asset-static-parsing'
import useAssetImportsQuery from '../data/use-asset-imports-query'
import useAssetStaticGridOptionsQuery from '../data/use-asset-static-grid-options'
import useAssetStaticQuery from '../data/use-asset-static-query'
import { useRequestAssetsSyncMutation } from '../data/use-request-assets-sync-mutation'
import AssetImportsMenu from './asset-imports-menu'
import AssetStaticGrid from './asset-static-grid'
import AssetStaticUploadModal from './asset-static-upload-modal'
import ContextMenu from './context-menu'
import DataEngineShell from './data-engine-shell'
import EditAssetStaticModals from './edit-asset-static-modals'
import QuickSearchField from './quick-search-field'
import RequestAssetImportModal from './request-asset-import-modal'

type AssetStaticDetailsProps = {
  assetTag: string
  onDatapointDetailsOpen: (
    assetRef: string,
    datapointRef: string,
    datasetRef: string,
    decimalPlaces: number | undefined
  ) => void
}

type ContextMenuState = {
  coordinates: { mouseX: number; mouseY: number }
  assetRef: string
  datapointRef: string
  decimalPlaces: number | undefined
}

function AssetStaticDetails(props: AssetStaticDetailsProps) {
  const { assetTag, onDatapointDetailsOpen } = props
  const { t } = useTranslation('dataEngine')

  const { user } = useUserInfo()

  const viewsMenu = useOpenState()

  const [searchParams, setSearchParams] = useSearchParams()

  // needed for grid to re-render when view changes, otherwise some columns keep their old values
  const [gridKey, setGridKey] = useState('')
  const [gridRef, setGridRef] = useState<RefObject<AgGridReact> | null>(null)

  const [parsedGridData, setParsedGridData] = useState<ParsedAssetGridData | null>(null)
  const [hiddenColumnsMenuAnchorEl, setHiddenColumnsMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [quickSearch, setQuickSearch] = useState('')
  const [activeAssetStaticKey, setActiveAssetStaticKey] = useState('')
  const [contextMenu, setContextMenu] = useState<ContextMenuState | null>(null)
  const uploadModal = useOpenState()

  const gridOptionsResponse = useAssetStaticGridOptionsQuery(assetTag)
  const gridOptions = gridOptionsResponse.data?.data

  const {
    viewConfigState,
    hideAllColumns,
    unhideAllColumns,
    toggleHiddenColumn,
    updateColumn,
    moveColumn,
    setColumnWidth,
    setView,
    setDataset,
    resetState,
    isStateReady,
  } = useViewConfigState(gridOptions)

  const { datasetRef, viewRef, aggregations, filters, sortBy, columns, isViewDirty } = viewConfigState

  const assetStaticQuery = useAssetStaticQuery(assetTag, datasetRef, { enabled: isStateReady })
  const data = assetStaticQuery.data?.data

  const isLoading = assetStaticQuery.isLoading || gridOptionsResponse.isLoading
  const isFetching = assetStaticQuery.isFetching || gridOptionsResponse.isFetching

  // todo: replace with useAssetsSummaryQuery
  const tradeTicketResponse = useTradeTicketQuery({ enabled: true })
  const tradeTicket = tradeTicketResponse.data?.data || null
  const currencies = tradeTicket?.currencies || {}
  const identifiers = tradeTicket?.identifier_types || []
  const indexes = tradeTicket?.indexes || {}
  const refRates = tradeTicket?.ref_rates || {}
  const regularCurves = tradeTicket?.regular_curves || {}

  const filteredData = useMemo(() => {
    const searchTerm = quickSearch.toLocaleLowerCase()
    const filteredDataRows = data?.rows.filter((row) => {
      for (let [_, v] of Object.entries(row.datapoints)) {
        if (typeof v.value === 'object') {
          let display: string | undefined

          if ('String' in v.value) {
            display = v.value.String
          } else if ('Classification' in v.value) {
            display = v.display_as
          } else if ('MultiSelect' in v.value) {
            display = v.display_as
          } else if ('AssetRef' in v.value) {
            display = v.display_as
          } else if ('UserRef' in v.value) {
            display = v.display_as
          } else if ('LEI' in v.value) {
            display = v.display_as
          }

          if (display && display.toLocaleLowerCase().includes(searchTerm)) {
            return true
          }
        }
      }
      return false
    })
    return filteredDataRows ? ({ ...data, rows: filteredDataRows } as StaticResponse) : data
  }, [data, quickSearch])

  const datasets = gridOptions?.datasets || []
  const selectedDatasetRef = datasetRef || data?.dataset
  const assetTypesOptions = gridOptions?.asset_types || []
  const views = gridOptions?.views || []
  const currentView = views.find((view) => view.view_ref === viewRef)
  const currentViewName = currentView?.view_name || ''

  const columnsOptions = parsedGridData?.columnsOptions || []
  const shownColumnsCount = parsedGridData?.shownColumnsCount

  const activeAssetStatic = activeAssetStaticKey
    ? parsedGridData?.rows
        .map((row) => row.cells)
        .flat()
        .find((cell) => cell.cellKey === activeAssetStaticKey)
    : undefined

  useEffect(() => {
    if (!assetStaticQuery.isFetching && !assetStaticQuery.isError) {
      searchParams.set('viewName', currentViewName)
      setSearchParams(searchParams)

      setGridKey(`asset-type-${assetTag}_view-${viewRef}`)
      setParsedGridData(parseAssetStaticData(filteredData, columns))
    }
  }, [filteredData, columns, currentViewName, assetStaticQuery.isFetching, assetStaticQuery.isError])

  useEffect(() => {
    // reset search when selected asset type changes
    setQuickSearch('')
  }, [assetTag])

  function reloadData() {
    assetStaticQuery.refetch()
    gridOptionsResponse.refetch()
  }

  function handleHiddenColumnsMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setHiddenColumnsMenuAnchorEl(event.currentTarget)
  }

  function handleHiddenColumnsMenuClose() {
    setHiddenColumnsMenuAnchorEl(null)
  }

  function handleUpdateColumn(datapointRef: string, name: string | undefined, decimalPlaces: number | undefined) {
    updateColumn(datapointRef, name, decimalPlaces, false, parsedGridData?.originalHeadings!)
  }

  function handleToggleHiddenColumn(datapointRef: string) {
    toggleHiddenColumn(datapointRef, parsedGridData?.originalHeadings!)
  }

  function handleMoveColumn(fromDatapointRef: string, toDatapointRef: string) {
    moveColumn(fromDatapointRef, toDatapointRef, parsedGridData?.originalHeadings!)
  }

  function handleSetColumnWidth(datapointRef: string, width: number) {
    setColumnWidth(datapointRef, width, parsedGridData?.originalHeadings!)
  }

  function handleEditAssetStaticOpen(key: string) {
    setActiveAssetStaticKey(key)
  }

  function handleResetAssetStaticKey() {
    setActiveAssetStaticKey('')
  }

  function handleContextMenuOpen(event: MouseEvent, cell: ParsedAssetGridCell) {
    if (cell.cellKey !== 'cell_asset_description') {
      event.preventDefault()

      setContextMenu({
        coordinates: {
          mouseX: event.clientX,
          mouseY: event.clientY,
        },
        assetRef: cell.assetRef,
        datapointRef: cell.meta?.datapoint_ref || '',
        decimalPlaces: cell.decimalPlaces,
      })
    }
  }

  function handleDetailsModalOpen() {
    const assetRef = contextMenu?.assetRef
    const datapointRef = contextMenu?.datapointRef
    const decimalPlaces = contextMenu?.decimalPlaces
    const datasetRef = selectedDatasetRef

    if (assetRef && datapointRef && datasetRef) {
      onDatapointDetailsOpen(assetRef, datapointRef, datasetRef, decimalPlaces)
      setContextMenu(null)
    }
  }

  function createSaveViewBody(): GridDataViewUpdateBody | undefined {
    if (!parsedGridData) {
      return
    }

    const body: GridDataViewUpdateBody = {
      view_options: {
        aggregation: aggregations,
        sort_by: sortBy,
        filters,
        panel_mode: null,
        // todo: double check if the columns here work correctly
        // specially when view being changed is not the current one
        columns: initColumnOrder(columns, parsedGridData.originalHeadings),
        asset_types: currentView?.view_options.asset_types || null,
        as_of_date: null,
      },
    }

    return body
  }

  function createSaveAsViewBody(params: ViewSaveAsParams): GridDataViewCreateBody | undefined {
    if (!selectedDatasetRef || !parsedGridData) {
      return
    }

    const body: GridDataViewCreateBody = {
      view_name: params.name,
      dataset_ref: selectedDatasetRef,
      view_type: 'asset_static_grid',
      view_options: {
        aggregation: aggregations,
        sort_by: sortBy,
        filters,
        panel_mode: null,
        columns: initColumnOrder(columns, parsedGridData.originalHeadings),
        asset_types: params.assetTypes || null,
        as_of_date: null,
      },
    }

    return body
  }

  function createEditViewBody(params: ViewEditParams): GridDataViewUpdateBody | undefined {
    if (!parsedGridData) {
      return
    }

    const body: GridDataViewUpdateBody = {
      view_name: params.viewName,
      owner_group_ref: params.ownerGroupRef,
      // todo: allow changing only asset_types here
      // view_options: {},
    }
    return body
  }

  function handleViewSaveAsSuccess(data: any) {
    setSearchParams({ viewRef: data.view_ref })
    gridOptionsResponse.refetch()
    viewsMenu.close()
  }

  async function handleViewDeleteSuccess(deletedViewRef: string) {
    if (deletedViewRef === viewRef) {
      viewsMenu.close()

      setSearchParams({})
      await gridOptionsResponse.refetch()
      setSearchParams({})
      resetState()

      return
    }

    gridOptionsResponse.refetch()
  }

  if (isLoading) {
    return <DataEngineShell />
  }

  return (
    <DataEngineShell
      headerRightMenu={
        <>
          <AssetImportsSection identifiers={identifiers} />

          <Tooltip title={t('asset_static_upload.upload_button_tooltip')} arrow disableInteractive>
            <IconButton onClick={uploadModal.open} sx={{ color: 'gray.300', padding: '4px', margin: '4px' }}>
              <Upload fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('portfolio:view_selector')}>
            <IconButton onClick={viewsMenu.open} sx={{ color: 'gray.300', padding: '4px', margin: '4px' }}>
              <Badge variant="dot" invisible={!isViewDirty} sx={{ '.MuiBadge-dot': { backgroundColor: 'arc.info' } }}>
                <Bookmark fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip>
        </>
      }
    >
      <Stack flex={1} p={2} gap={2}>
        <Stack direction="row" gap={1}>
          <QuickSearchField
            key={assetTag} // reset search when selected asset type changes
            placeholder={t('quick_search')}
            onValueChange={setQuickSearch}
          />
          <ReloadButton loading={isFetching} onClick={reloadData} sx={{ mr: 'auto' }} />
          <FilterButton
            label={t('hidden_columns')}
            count={shownColumnsCount}
            Icon={ViewColumn}
            onClick={handleHiddenColumnsMenuOpen}
          />
        </Stack>

        <AssetStaticGrid
          key={gridKey}
          data={parsedGridData}
          onEditAssetStatic={handleEditAssetStaticOpen}
          onContextMenuOpen={handleContextMenuOpen}
          onForwardGridRef={setGridRef}
          onUpdateColumn={handleUpdateColumn}
          onHideColumn={handleToggleHiddenColumn}
          onMoveColumn={handleMoveColumn}
          onSetColumnWidth={handleSetColumnWidth}
        />
      </Stack>

      <ViewOptionsMenu
        open={viewsMenu.isOpen}
        views={views}
        defaultViewRef={gridOptions?.default_view_ref}
        datasets={datasets}
        assetTypes={assetTypesOptions}
        isViewDirty={isViewDirty}
        userGroupRef={user?.group_ref}
        currentView={currentView}
        assetType={assetTag}
        onViewSelect={setView}
        onDatasetSelect={setDataset}
        createSaveViewBody={createSaveViewBody}
        createSaveAsViewBody={createSaveAsViewBody}
        createEditViewBody={createEditViewBody}
        onViewSaveAsSuccess={handleViewSaveAsSuccess}
        onViewDeleteSuccess={handleViewDeleteSuccess}
        onClose={viewsMenu.close}
      />

      <ColumnsMenu
        anchorEl={hiddenColumnsMenuAnchorEl}
        columns={columnsOptions}
        onHideAll={hideAllColumns}
        onUnhideAll={unhideAllColumns}
        onToggleHiddenColumn={handleToggleHiddenColumn}
        onClose={handleHiddenColumnsMenuClose}
      />

      {activeAssetStatic && (
        <EditAssetStaticModals
          isAssetActive={!!activeAssetStatic}
          assetRef={activeAssetStatic.assetRef}
          assetCategory={activeAssetStatic.meta?.asset_category}
          assetDescription={activeAssetStatic.assetDesc || ''}
          datapointRef={activeAssetStatic.meta?.datapoint_ref}
          datapointName={activeAssetStatic.meta?.datapoint_name || ''}
          datapointValue={activeAssetStatic.value}
          datapointType={activeAssetStatic.meta?.datapoint_type}
          datadocType={activeAssetStatic.meta?.datadoc_type}
          classificationId={activeAssetStatic.meta?.classification_id}
          canAutogenerate={activeAssetStatic.canAutogenerate}
          datasetRef={datasetRef}
          currencies={currencies}
          indexes={indexes}
          refRates={refRates}
          regularCurves={regularCurves}
          tradeTicket={tradeTicket}
          resetActiveAsset={handleResetAssetStaticKey}
        />
      )}

      <AssetStaticUploadModal
        open={uploadModal.isOpen}
        datasetRef={selectedDatasetRef}
        onSucess={assetStaticQuery.refetch}
        onClose={uploadModal.close}
      />
      <ContextMenu
        coordinates={contextMenu?.coordinates}
        onClose={() => setContextMenu(null)}
        onDetailsClick={handleDetailsModalOpen}
        onCopy={() => gridRef?.current?.api.copySelectedRangeToClipboard()}
      />
    </DataEngineShell>
  )
}

export default AssetStaticDetails

type AssetImportsSectionProps = {
  identifiers: TradeTicketResponse['identifier_types']
}

function AssetImportsSection(props: AssetImportsSectionProps) {
  const { identifiers } = props

  const { t } = useTranslation('dataEngine')

  const menu = useOpenState()
  const importModal = useOpenState()
  const pendingModal = useOpenState()
  const syncModal = useOpenState()
  const buttonRef = useRef<HTMLButtonElement>(null)

  const requestAssetsSync = useRequestAssetsSyncMutation()

  const assetImportsQuery = useAssetImportsQuery()
  const assetImports = assetImportsQuery.data?.data || []
  const hasPendingImports = assetImports.some((i) => i.status === 'pending')

  function handleRequestAssetsSync() {
    menu.close()

    requestAssetsSync.mutate(undefined, {
      onSuccess(data) {
        assetImportsQuery.refetch()
        syncModal.close()

        if (data.data.import_pending) {
          pendingModal.open()
        }
      },
    })
  }

  return (
    <>
      <Tooltip title={t('asset_imports.asset_imports')} arrow disableInteractive>
        <IconButton
          ref={buttonRef}
          onClick={() => {
            assetImportsQuery.refetch()
            menu.open()
          }}
          sx={{ color: 'gray.300', padding: '4px', margin: '4px' }}
        >
          <Badge variant="dot" invisible={!hasPendingImports} sx={{ '.MuiBadge-dot': { backgroundColor: 'arc.info' } }}>
            <PlaylistAdd fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>

      <AssetImportsMenu
        open={menu.isOpen}
        anchorEl={buttonRef.current}
        assetImports={assetImports}
        onRequestImport={() => {
          menu.close()
          importModal.open()
        }}
        onRequestSync={() => {
          menu.close()
          syncModal.open()
        }}
        onRefetchImports={assetImportsQuery.refetch}
        onClose={menu.close}
      />
      <RequestAssetImportModal
        open={importModal.isOpen}
        identifiers={identifiers}
        onSucess={(importPending) => {
          assetImportsQuery.refetch()
          importModal.close()

          if (importPending) {
            pendingModal.open()
          }
        }}
        onClose={importModal.close}
      />

      <ConfirmationModal
        title={t('asset_imports.pending_import_modal.title')}
        confirmButtonText={t('asset_imports.pending_import_modal.button_label')}
        open={pendingModal.isOpen}
        onCloseButtonClick={pendingModal.close}
        onConfirmButtonClick={() => {
          assetImportsQuery.refetch()
          pendingModal.close()
          menu.open()
        }}
      >
        <Typography variant="body1">{t('asset_imports.pending_import_modal.message')}</Typography>
      </ConfirmationModal>
      <ConfirmationModal
        open={syncModal.isOpen}
        isLoading={requestAssetsSync.isLoading}
        error={requestAssetsSync.error}
        title={t('asset_imports.sync.confirm_title')}
        confirmButtonText={t('common:confirm')}
        onConfirmButtonClick={handleRequestAssetsSync}
        onCloseButtonClick={syncModal.close}
      >
        <Typography variant="body1">{t('asset_imports.sync.confirm_message')}</Typography>
      </ConfirmationModal>
    </>
  )
}
