import { DeleteOutline } from '@mui/icons-material'
import { Autocomplete, Button, Dialog, Divider, IconButton, Stack, TextField, Typography } from '@mui/material'
import { FormEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumericField from '../../../components/fields/numeric-field'
import ModalActions from '../../../components/modal-actions'
import ModalContent from '../../../components/modal-content'
import { ModalDetailRow } from '../../../components/modal-detail-row'
import ModalTitle from '../../../components/modal-title'
import { PortfolioHeader } from '../../../services/data/types/portfolio'
import {
  ReconBankTxn,
  ReconMatch,
  ReconRecord,
  ReconTxn,
  ReconTxnLocal,
} from '../../../services/data/types/reconciliation'
import { formatDatetime } from '../../../utils/dates'
import { newLocalKey } from '../../../utils/id'
import { defaultInputDecimalPlaces, formatFloat } from '../../../utils/numbers'
import { SelectedTxns } from '../data/reconciliation-data'

type MatchModalProps = {
  open: boolean
  record: ReconRecord | null
  transactions: SelectedTxns
  matches: Array<ReconMatch>
  portfolios: PortfolioHeader[]
  accountPortfolioRef: string | null | undefined
  onSave: (match: ReconMatch) => void
  onClose: () => void
}

type TxnAmounts = {
  [extSegmentRef: string]: Match
}

type Match = {
  extSegmentRef: string
  amount: string
  portfolioRef: string
  bankTxnRef: string | null
  cpartyId: number | null
  description: string | null
}

type NewBankTxn = {
  local_key: string
  portfolio: PortfolioHeader | null
  amount: string
  description: string
}

function MatchModal(props: MatchModalProps) {
  const { open, record, transactions, matches, portfolios, accountPortfolioRef, onSave, onClose } = props

  const { t } = useTranslation('bankAccounts')

  const formRef = useRef<HTMLFormElement>(null)
  const [amounts, setAmounts] = useState<TxnAmounts>({})
  const [newTxns, setNewTxns] = useState<NewBankTxn[]>([])

  const txns = useMemo(() => Object.values(transactions), [transactions])

  const amountsTotal = Object.values(amounts).reduce((acc, item) => acc + (Number(item.amount) || 0), 0)
  const newTxnsTotal = newTxns.reduce((acc, t) => acc + Number(t.amount), 0)
  const total = amountsTotal + newTxnsTotal

  let canMatch = record && total != 0 && record.amount_to_reconcile === total

  useEffect(() => {
    if (!open) {
      setAmounts({})
      setNewTxns([])
    } else if (record) {
      setAmounts(getInitialAmounts(record, txns, matches))
    }
  }, [open, record])

  function handleAmountUpdate(txn: ReconTxn, bankTxn: ReconBankTxn | null, value: string) {
    const extSegmentRef = bankTxn?.ext_segment_ref || txn.ext_segment_ref
    const match = createMatch(txn, bankTxn, value)
    setAmounts((prev) => ({ ...prev, [extSegmentRef]: match }))
  }

  function handleAddNewBankTxn() {
    setNewTxns((prev) => {
      const portfolio = portfolios.find((p) => p.portfolio_ref === accountPortfolioRef) || null
      return prev.concat([
        {
          local_key: newLocalKey(),
          portfolio,
          amount: '',
          description: '',
        },
      ])
    })
  }

  function handleClose() {
    onClose()
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    if (!canMatch || !record) {
      return
    }

    const reconMatch: ReconMatch = {
      record_id: record.record_id,
      txns: [],
    }

    Object.entries(amounts).forEach(([extSegmentRef, item]) => {
      const numberAmount = Number(item.amount)

      if (!!numberAmount) {
        reconMatch.txns.push({
          ext_segment_ref: extSegmentRef,
          amount: numberAmount,
          portfolio_ref: item.portfolioRef,
          bank_txn_ref: item.bankTxnRef,
          cparty_id: item.cpartyId,
          note: item.description,
        })
      }
    })

    for (const newTxn of newTxns) {
      const numberAmount = Number(newTxn.amount)

      if (!!numberAmount && newTxn.portfolio) {
        reconMatch.txns.push({
          ext_segment_ref: null,
          amount: numberAmount,
          portfolio_ref: newTxn.portfolio.portfolio_ref,
          bank_txn_ref: null,
          cparty_id: null,
          note: newTxn.description,
        })
      }
    }

    onSave(reconMatch)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // closeAfterTransition needed because:
      // https://github.com/mui/material-ui/issues/43106
      closeAfterTransition={false}
      PaperProps={{
        sx: {
          position: 'fixed',
          top: '10%',
          maxHeight: '80%',
          my: 0,
        },
      }}
    >
      <form ref={formRef} onSubmit={handleSubmit}>
        <ModalTitle title={t('match_modal.title')} onClose={handleClose} />
        <ModalContent>
          <Typography variant="subtitle1">{t('match_modal.bank_record')}</Typography>

          <ModalDetailRow label={t('match_modal.date')} value={record?.date} />
          <ModalDetailRow label={t('match_modal.description')} value={record?.description} />
          <ModalDetailRow label={t('match_modal.amount')} value={formatFloat(record?.amount)} />

          <Divider />

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1">{t('match_modal.transactions')}</Typography>
            <Button variant="text" size="small" onClick={handleAddNewBankTxn}>
              {t('match_modal.add_bank_payment')}
            </Button>
          </Stack>

          {txns.map((txn) => {
            const rows: ReactNode[] = []

            // ignore when root txn is bank txn, as a single bank txn is expected and they are the same
            if (!txn.is_bank_txn) {
              for (const bank_txn of txn.bank_txns) {
                if (bank_txn.is_reconciled) {
                  continue
                }

                const amountStr = amounts[bank_txn.ext_segment_ref]?.amount || ''
                const amountNum = Number(amountStr)
                let fieldError = ''
                if (amountNum !== 0 && amountNum !== bank_txn.amount) {
                  canMatch = false
                  fieldError = t('match_modal.existing_txn_need_to_fully_match')
                }

                const label = `${formatDatetime(bank_txn.txn_datetime)}  |  ${formatFloat(bank_txn.amount)}`

                rows.push(
                  <FormRow
                    key={bank_txn.ext_segment_ref}
                    label={
                      <div>
                        <div style={{ color: 'white' }}>{label}</div>
                        <div>{bank_txn.description}</div>
                      </div>
                    }
                  >
                    <NumericField
                      disabled
                      fixedLabel
                      size="small"
                      label={t('common:amount')}
                      value={amountStr}
                      error={fieldError}
                      decimalPlaces={defaultInputDecimalPlaces}
                      onValueChange={(value) => handleAmountUpdate(txn, bank_txn, value)}
                    />
                  </FormRow>
                )
              }
            }

            let bankTxn: ReconBankTxn | null
            if (txn.is_bank_txn) {
              // when root txn is bank txn, a single bank txn is expected
              bankTxn = txn.bank_txns[0] || null
            }

            const label = `${txn.settlement_date}  |  ${formatFloat(txn.quantity)}`

            rows.push(
              <FormRow
                key={txn.ext_segment_ref}
                label={
                  <div>
                    <div style={{ color: 'white' }}>{label}</div>
                    <div>{txn.description}</div>
                  </div>
                }
              >
                <NumericField
                  disabled={txn.is_bank_txn}
                  fixedLabel
                  size="small"
                  label={t('common:amount')}
                  value={amounts[txn.ext_segment_ref]?.amount || ''}
                  decimalPlaces={defaultInputDecimalPlaces}
                  onValueChange={(value) => handleAmountUpdate(txn, bankTxn, value)}
                />
              </FormRow>
            )

            return rows
          })}

          {!!newTxns.length && <Divider />}

          {newTxns.map((newTxn, index) => (
            <Stack key={newTxn.local_key} gap={2} mb={2}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="subtitle1">{`${index + 1}. ${t('match_modal.new_bank_payment')}`}</Typography>
                <IconButton
                  size="small"
                  color="error"
                  onClick={(_) => {
                    setNewTxns((prev) => prev.filter((prev) => prev.local_key !== newTxn.local_key))
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </Stack>
              <Autocomplete
                value={newTxn.portfolio}
                options={portfolios}
                getOptionLabel={(p) => p.portfolio_name}
                isOptionEqualToValue={(option, value) => option.portfolio_ref === value.portfolio_ref}
                onChange={(_, portfolio) => {
                  setNewTxns((prev) => {
                    return prev.map((prevTxn) => {
                      if (prevTxn.local_key === newTxn.local_key) {
                        return { ...prevTxn, portfolio: portfolio }
                      }
                      return prevTxn
                    })
                  })
                }}
                renderInput={(props) => <TextField {...props} required size="small" label={t('common:portfolio')} />}
              />
              <NumericField
                required
                size="small"
                name="amount"
                label={t('common:amount')}
                value={newTxn.amount}
                decimalPlaces={defaultInputDecimalPlaces}
                onValueChange={(amount) => {
                  setNewTxns((prev) => {
                    return prev.map((prevTxn) => {
                      if (prevTxn.local_key === newTxn.local_key) {
                        return { ...prevTxn, amount }
                      }
                      return prevTxn
                    })
                  })
                }}
              />
              <TextField
                required
                size="small"
                name="description"
                label={t('create_bank_transaction_modal.description')}
                value={newTxn.description}
                multiline
                minRows={1}
                maxRows={4}
                onChange={(event) => {
                  setNewTxns((prev) => {
                    return prev.map((prevTxn) => {
                      if (prevTxn.local_key === newTxn.local_key) {
                        return { ...prevTxn, description: event.target.value }
                      }
                      return prevTxn
                    })
                  })
                }}
              />
            </Stack>
          ))}

          <ModalDetailRow
            label={t('match_modal.total')}
            value={`${formatFloat(total)} / ${formatFloat(record?.amount_to_reconcile)}`}
            valueColor={canMatch ? 'success.main' : 'error'}
          />
        </ModalContent>

        <ModalActions
          confirmLabel={t('match_modal.confirm_match')}
          confirmDisabled={!canMatch}
          onCancel={handleClose}
        />
      </form>
    </Dialog>
  )
}

export default MatchModal

type FormRowProps = {
  label: string | ReactNode
  children: ReactNode
}

export function FormRow(props: FormRowProps) {
  const { label, children } = props

  return (
    <Stack gap={1}>
      <Typography variant="caption" fontSize="small" color="gray.700" whiteSpace="pre-wrap">
        {label}
      </Typography>

      {children}
    </Stack>
  )
}

function getInitialAmounts(record: ReconRecord, txns: ReconTxnLocal[], matches: ReconMatch[]) {
  const initialAmounts: TxnAmounts = {}

  let recordAmtToReconcile = record.amount_to_reconcile

  for (const txn of txns) {
    if (txn.is_bank_txn) {
      const bankTxn = txn.bank_txns[0] || null
      const amount = getAmountLimit(recordAmtToReconcile, txn.quantity_settled + txn.local_new_quantity_settled)
      if (amount) {
        initialAmounts[txn.ext_segment_ref] = createMatch(txn, bankTxn, String(amount))
      }
      recordAmtToReconcile -= amount

      if (recordAmtToReconcile === 0) {
        break
      }
    } else {
      let amtUsed = 0

      for (const bankTxn of txn.bank_txns) {
        if (bankTxn.is_reconciled) {
          continue
        }

        const matchedAmount = getMatchedAmount(matches, bankTxn.ext_segment_ref)
        const amount = getAmountLimit(recordAmtToReconcile, bankTxn.amount - matchedAmount)
        if (amount) {
          initialAmounts[bankTxn.ext_segment_ref] = createMatch(txn, bankTxn, String(amount))
        }

        amtUsed += amount
        recordAmtToReconcile -= amount

        if (recordAmtToReconcile === 0) {
          break
        }
      }

      const amount = getAmountLimit(recordAmtToReconcile, txn.quantity + txn.local_total_quantity_settled - amtUsed)
      if (amount) {
        initialAmounts[txn.ext_segment_ref] = createMatch(txn, null, String(amount))
      }
      amtUsed += amount
      recordAmtToReconcile -= amount

      if (recordAmtToReconcile === 0) {
        break
      }
    }
  }

  return initialAmounts
}

// Gets the matched amount for the given extSegmentRef,
// that being from a trade or bank payment, the caller decides which ref to use.
export function getMatchedAmount(matches: ReconMatch[], extSegmentRef: string): number {
  let matchedAmount = 0
  for (const match of matches) {
    for (const txn of match.txns) {
      if (txn.ext_segment_ref === extSegmentRef) {
        matchedAmount += txn.amount
      }
    }
  }
  return matchedAmount
}

// Gets the appropriate max or min amount, depending on the record amount sign.
function getAmountLimit(recordAmt: number, txnAmt: number) {
  if (recordAmt < 0) {
    return Math.max(recordAmt, txnAmt)
  } else {
    return Math.min(recordAmt, txnAmt)
  }
}

function createMatch(txn: ReconTxn, bankTxn: ReconBankTxn | null, value: string) {
  const extSegmentRef = bankTxn?.ext_segment_ref || txn.ext_segment_ref
  const match: Match = {
    extSegmentRef,
    amount: value,
    portfolioRef: txn.portfolio_ref,
    bankTxnRef: bankTxn?.txn_ref || null,
    cpartyId: txn.cparty_id,
    description: bankTxn?.description || txn.description,
  }
  return match
}
