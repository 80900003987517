import { ChevronRight, Search } from '@mui/icons-material'
import {
  Box,
  Button,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ChangeEvent, MouseEvent, MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteFilterPayload } from '../../modules/portfolios/data/use-view-config-state'
import { GridDataFilterOption, ParsedFilter } from '../../services/data/filter-parsing'
import { GridDataViewFilters } from '../../services/data/types/grid-data-view'
import useOpenStateWithData from '../../utils/hooks/use-open-state-with-data'
import FilterChips from './filter-chips'
import FilterMenuLabel from './filter-menu-label'
import FilterMenuOptions from './filter-menu-options'

type FilterMenuProps = {
  anchorEl: HTMLElement | null
  filterOptions: GridDataFilterOption[]
  selectedFilters: ParsedFilter[]
  hideActiveFilters?: boolean
  onChange: (filters: GridDataViewFilters) => void
  onDelete: (payload: DeleteFilterPayload) => void
  onDeleteAll: () => void
  onClose?: () => void
}

function FilterMenu(props: FilterMenuProps) {
  const { anchorEl, filterOptions, selectedFilters, hideActiveFilters, onChange, onDelete, onDeleteAll, onClose } =
    props
  const { t } = useTranslation('dataTable')

  const filterMenu = useOpenStateWithData<HTMLElement>()
  const [activeMenuDatapointRef, setActiveMenuDatapointRef] = useState('')
  const [searchValue, setSearchValue] = useState('')

  const filteredOptions = filterOptions.filter((option) => {
    return (
      option.columnName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
      option.datapointName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    )
  })

  const showFilterChips = !hideActiveFilters && !!selectedFilters.length

  function handleFilterOptionMenuOpen(event: MouseEvent<HTMLElement>, datapointRef: string) {
    filterMenu.open(event.currentTarget)
    setActiveMenuDatapointRef(datapointRef)
  }

  function handleFilterOptionMenuClose() {
    filterMenu.close()
    setActiveMenuDatapointRef('')
  }

  function handleClose() {
    handleFilterOptionMenuClose()
    onClose?.()
    setSearchValue('')
  }

  function handleClearAllClick() {
    onDeleteAll()
    handleClose()
  }

  function handleSearchChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setSearchValue(event.target.value)
  }

  return (
    <>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: { mt: 1, width: '350px' },
        }}
        // closeAfterTransition needed because:
        // https://github.com/mui/material-ui/issues/43106
        closeAfterTransition={false}
      >
        <Stack direction="row" pr={2}>
          <FilterMenuLabel>{t('filter_by')}</FilterMenuLabel>
          <Button variant="text" onClick={handleClearAllClick} sx={{ fontSize: 13, ml: 'auto' }}>
            {t('clear_all')}
          </Button>
        </Stack>

        {showFilterChips && (
          <Stack direction="row" flexWrap="wrap" gap={1} px={2} py={1}>
            <FilterChips filters={selectedFilters} onDelete={onDelete} />
          </Stack>
        )}

        <TextField
          size="small"
          label={t('search_columns')}
          autoFocus
          onKeyDown={(event) => event.stopPropagation()}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{<Search />}</InputAdornment>,
          }}
          InputLabelProps={{ shrink: true }}
          sx={{ my: 1, ml: 2.5, width: '88%' }}
        />

        <Box sx={{ height: '100%', maxHeight: '50vh', overflowY: 'auto' }}>
          {filteredOptions.map((option) => (
            <FilterMenuItem
              key={option.datapointRef}
              label={option.columnName}
              selected={activeMenuDatapointRef === option.datapointRef}
              onClick={(event) => handleFilterOptionMenuOpen(event, option.datapointRef)}
            />
          ))}
        </Box>
      </Menu>

      <FilterMenuOptions
        anchorEl={filterMenu.data}
        filterOptions={filterOptions}
        selectedFilters={selectedFilters}
        activeMenuDatapointRef={activeMenuDatapointRef}
        disableBackdrop
        onChange={onChange}
        onDelete={onDelete}
        onClose={handleFilterOptionMenuClose}
      />
    </>
  )
}

export default FilterMenu

type FilterMenuItemProps = {
  label: string
  selected: boolean
  onClick: MouseEventHandler<HTMLElement>
}

function FilterMenuItem(props: FilterMenuItemProps) {
  const { label, selected, onClick } = props

  return (
    <MenuItem
      onClick={onClick}
      selected={selected}
      sx={{
        my: 0.5,
        py: 1,
        '&:hover .MuiSvgIcon-root': {
          opacity: 1,
        },
        '&.Mui-selected': {
          background: 'rgba(120, 220, 232, 0.08)',
          '& .MuiSvgIcon-root': {
            opacity: 1,
          },
        },
      }}
    >
      <ListItemText>
        <Typography variant="body2" whiteSpace="pre-wrap">
          {label}
        </Typography>
      </ListItemText>
      <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
        <ChevronRight color="primary" sx={{ fontSize: 18, opacity: 0 }} />
      </ListItemIcon>
    </MenuItem>
  )
}
