import { FilterAlt, MoreVert } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { MouseEvent, useRef } from 'react'
import { ReconGridHeading, ReconGridHeadingKey } from '../../../services/data/types/reconciliation'
import useOpenState from '../../../utils/hooks/use-open-state'
import useOpenStateWithData from '../../../utils/hooks/use-open-state-with-data'
import { ReconFilterStateValue } from '../data/reconciliation-filters'
import { ReconColumnHeaderMenu } from './recon-column-header-menu'
import { ReconFilterMenuOptions } from './recon-filter-menu-options'

type ReconColumnHeaderProps = {
  heading: ReconGridHeading
  filter: ReconFilterStateValue | undefined
  onAddFilter: (key: ReconGridHeadingKey, filter: ReconFilterStateValue) => void
  onDeleteFilter: (key: ReconGridHeadingKey) => void
}

export function ReconColumnHeader(props: ReconColumnHeaderProps) {
  const { heading, filter, onAddFilter, onDeleteFilter } = props

  const outer = useRef(null)
  const menuButton = useOpenState({ open: false })
  const columnMenu = useOpenState()
  const filterMenu = useOpenStateWithData<HTMLElement>()

  const hasMenuOptions = !!heading.filterType
  const showMenuButton = hasMenuOptions && (menuButton.isOpen || columnMenu.isOpen)

  const hasFilters = !!filter

  function handleFilterMenuToggle(event: MouseEvent<HTMLElement>) {
    filterMenu.toggle(event.currentTarget)
  }

  function handleMenuClose() {
    columnMenu.close()
    filterMenu.close()
  }

  return (
    <>
      <div
        ref={outer}
        key={heading.key}
        onMouseOver={menuButton.open}
        onMouseLeave={menuButton.close}
        onContextMenu={(event) => {
          event.preventDefault()
          if (hasMenuOptions) {
            columnMenu.toggle()
          }
        }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', mr: 'auto' }}>
          <Typography fontSize="14px" fontWeight="500">
            {heading.title}
          </Typography>

          {hasFilters && (
            <IconButton
              size="small"
              onClick={handleFilterMenuToggle}
              sx={{
                background: 'var(--af-header-background-color)',
                ':hover': {
                  background: 'var(--af-header-background-color)',
                },
              }}
            >
              <FilterAlt fontSize="small" sx={{ color: 'gray.300' }} />
            </IconButton>
          )}
        </Stack>

        <div style={{ width: '15px', height: '10px' }} />

        {showMenuButton && (
          <IconButton
            size="small"
            onClick={columnMenu.toggle}
            sx={{
              color: 'gray.300',
              background: 'var(--af-header-background-color)',
              ':hover': {
                background: 'var(--af-header-background-color)',
              },
            }}
          >
            <MoreVert fontSize="small" />
          </IconButton>
        )}
      </div>

      {columnMenu.isOpen && (
        <ReconColumnHeaderMenu
          anchorEl={outer.current}
          isFilterMenuOpen={filterMenu.isOpen}
          onFilterMenuClose={handleMenuClose}
          onFilterMenuToggle={handleFilterMenuToggle}
        />
      )}

      {filterMenu.isOpen && (
        <ReconFilterMenuOptions
          anchorEl={filterMenu.data}
          heading={heading}
          filter={filter}
          onChange={onAddFilter}
          onDelete={onDeleteFilter}
          onClose={handleMenuClose}
        />
      )}
    </>
  )
}
