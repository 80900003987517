import { useMemo } from 'react'
import { GridDataFilterOption, parseFilterOptions } from '../../../services/data/filter-parsing'
import { ParsedGridColumnOption } from '../../../services/data/grid-data-parsing'
import { useAssetsSummaryQuery } from '../../data-engine/data/use-assets-summary-query'
import useClassificationsQuery from '../../data-engine/data/use-classifications-query'
import useSystemClassificationsQuery from '../../data-engine/data/use-system-classifications-query'

export function useFilterOptions(
  columnOptions: ParsedGridColumnOption[] = [],
  datasetRef?: string | null
): [isFetching: boolean, options: GridDataFilterOption[]] {
  const systemClassificationsQuery = useSystemClassificationsQuery()
  const datasetClassificationsQuery = useClassificationsQuery(datasetRef)
  const assetsQuery = useAssetsSummaryQuery()

  const isFetching =
    systemClassificationsQuery.isFetching || datasetClassificationsQuery.isFetching || assetsQuery.isFetching

  const systemClassifications = systemClassificationsQuery.data?.data || []
  const datasetClassfications = datasetClassificationsQuery.data?.data || []
  const assets = assetsQuery.data?.data || []

  const options = useMemo(() => {
    const allClassifications = [...systemClassifications, ...datasetClassfications]
    return parseFilterOptions(columnOptions, allClassifications, assets)
  }, [columnOptions, systemClassifications, datasetClassfications, assets])

  return [isFetching, options]
}
